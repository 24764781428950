import { Controller } from "stimulus";
import { PaintSurface } from "libraries/libpaint";
import { EventListenerManager } from "libraries/libevents";
import { BrushSelectorMenu } from "libraries/libmenu.js";
import { write, read } from "libraries/libconnect";

import Brush from "libraries/libbrush";

function enable(element) {
  element.classList.add('enabled');
}
function disable(element) {
  element.classList.remove('enabled');
}
function markActive(element) {
  element.classList.add('active');
}
function markInactive(element) {
  element.classList.remove('active');
}
function hide(element) {
  element.classList.add("is-hidden");
}
function show(element) {
  element.classList.remove("is-hidden");
}
function isHidden(element) {
  return element.classList.contains("is-hidden");
}

const SETTINGS_COLOR_PICKER_KEY = "paintColor";

export default class extends Controller {

  static targets = [ "canvas", "bsInput", "colorInput", "opstackOut", "serializeBtn",
                     "undoBtn", "redoBtn", "clearBtn", "paintStackCanvas", "paintStackContainer",
                     "paintButton", "eraseButton", "playerWrapper", "togglePaintStackBtn", "commentMessageBox",
                     "paintControls", "commentFormBtns", "commentFormDrawingIcon", "saveBtn",
                     "brushIndicator", "brushSizeButton", "paintContainer", "togglePaintBtn",
                     "commentList", "commentDrawing", "brushSizesMenu", "brushSizeOption" ];


  showPaintStack() {
    show(this.paintStackContainerTarget);
    this.togglePaintStackBtnTarget.classList = ["btn-showing-paint-stack"];
    this.paintStackState = "shown";
  }

  hidePaintStack() {
    hide(this.paintStackContainerTarget)
    this.togglePaintStackBtnTarget.classList = ["btn-hiding-paint-stack"];
    this.paintStackState = "hidden";
  }

  togglePaintStackContainer() {
    this.paintStackState === "hidden" ? this.showPaintStack() : this.hidePaintStack();
  }

  setPaintStackDisplayTo(state) {
    state === "hidden" ? this.hidePaintStack() : this.showPaintStack();
  }

  togglePaintContainer(evt) {
    evt.preventDefault();
    if (isHidden(this.paintContainerTarget)) {
      // Remember paintstackstate for later, but dont always update it when we press this button
      this.oldPaintStackState = this.paintStackState;
      // show paint
      show(this.paintContainerTarget);
      show(this.paintControlsTarget);
      this.togglePaintBtnTarget.classList = ["btn-showing-paint"];
      if (this.opstackOutTarget.value) show(this.commentFormBtnsTarget);
      if(this.paintStackState === "hidden") this.showPaintStack();
    } else {
      // hide paint
      hide(this.paintContainerTarget);
      hide(this.paintControlsTarget);
      this.togglePaintBtnTarget.classList = ["btn-hiding-paint"];
      hide(this.commentFormBtnsTarget);
      // revert back to paintstackstate to old state
      if (this.paintStackState !== this.oldPaintStackState) {
        this.setPaintStackDisplayTo(this.oldPaintStackState);
      }
    }
  }

  addImageToStackCanvas(image) {
    this.paintStackBufferCtx.drawImage(image, 0, 0, this.paintStackCanvasTarget.width, this.paintStackCanvasTarget.height);
  }

  clearStackCanvas() {
    this.paintStackBufferCtx.clearRect(0, 0, this.paintStackCanvasTarget.width, this.paintStackCanvasTarget.height);
  }

  showDrawing(e) {
    if (e.target.tagName !== "IMG") return;
    // gets curve data from jsonDrawing and draws them on the paint canvas
    // used in showing a drawing from a comment
    const curvEl = e.target.parentElement.parentElement.querySelector(".jsonDrawing");
    const curves = JSON.parse(curvEl.innerText);

    if (curves) {
      show(this.paintContainerTarget);
      show(this.paintControlsTarget);
      this.paintEngine.clear();
      this.paintEngine.opstack = curves;
      this.paintEngine.render();
      window.scrollTo(0, 0);
    }
  }

  clear() {
    this.paintEngine.clear();
    this.updateDrawingOnScreen({detail: this.playerWrapperTarget.dataset.frame});
    hide(this.commentFormDrawingIconTarget);
    hide(this.commentFormBtnsTarget);
    if (this.commentMessageBoxTarget.value.trim() === "" || this.commentMessageBoxTarget.value === null) {
      this.saveBtnTarget.setAttribute("disabled", true);
    }
  }

  sizeChanged(e) {
    this.paintEngine.brushSize = parseFloat(e.target.value);
    console.log(`Applying bs ${this.paintEngine.brushSize}`);
    Brush.applyBrushCursor(this.canvasTarget, this.paintEngine.brushSize);
  };

  colorChanged(e) {
    const newColor = e.target.value;
    // Save it into settings
    write(SETTINGS_COLOR_PICKER_KEY, newColor);
    this.paintEngine.color = newColor;
  }

  switchToPaintMode() {
    this.paintEngine.mode = 'paint';
    this.updateModeState();
  }

  switchToEraseMode() {
    this.paintEngine.mode = 'erase';
    this.updateModeState();
  }

  undo(e) {
    this.paintEngine.undo();
  }

  redo() {
    this.paintEngine.redo();
  }

  opstackChanged(evt) {
    // when new curves are being drawn this is called so we can update opstackOutTarget automatically
    const {opstack} = evt.detail;
    console.log(`Drawing updated with opstack of ${opstack.length} elements on the opstack`);
    this.opstackOutTarget.value = this.paintEngine.opstackAsJSON();
    this.updateUndoRedoButtonState();
    show(this.commentFormBtnsTarget);
    show(this.commentFormDrawingIconTarget);
    this.saveBtnTarget.removeAttribute("disabled");
  }

  brushResizeChanged({detail}) {
    this.sizeChanged({target: {value: this.paintEngine.brushSize + detail}});
  }

  activateBrushMenu(evt) {
    this.brushMenu.activateMenu(evt);
  }

  setBrushSize(value) {
    this.paintEngine.brushSize = value * 6;
    Brush.applyBrushCursor(document.body, this.paintEngine.brushSize);
    Brush.applyBrushCursor(this.canvasTarget, this.paintEngine.brushSize);
  }

  updateDrawingOnScreen(e) {
    this.clearStackCanvas();
    const frameNumber = e.detail;

    const drawingsForThisFrame = this.orderedDrawings[frameNumber];
    if (!drawingsForThisFrame) return;

    this.orderedDrawings[e.detail].forEach(img => {
      if (img.complete) {
        this.addImageToStackCanvas(img);
     } else {
       // Only draw once we know that the image has loaded
       const preservedSrc = img.src;
       img.onload = () => this.addImageToStackCanvas(img);
       img.src = preservedSrc;
     }
    });
  }

  collectDrawingsFromComments() {
    // Collect all paint drawings under comments and store them in a hash ordered by frame number
    // with the frame number as key and an array of image elements as value. Like this for ex:
    // { 20: [ <img src..../>, <img src...../> ],
    //   76: [<img src.../>] }
    //
    this.orderedDrawings = {};
    this.commentDrawingTargets.forEach(drawing => {
      const key = parseInt(drawing.dataset.frame);
      const value = this.orderedDrawings[key] === undefined ? [drawing] : [drawing].concat(this.orderedDrawings[key]);
      this.orderedDrawings = Object.assign(this.orderedDrawings, { [key]: value });
    });
  }

  initialize() {
    this.paintStackState = "hidden";
  }

  connect() {
    this.setPaintStackDisplayTo(this.paintStackState);
    this.paintEngine = new PaintSurface(this.canvasTarget);
    this.paintEngine.brushSize = 15;
    this.paintEngine.splineType = "quadraticBezier";

    this.paintStackBufferCtx = this.paintStackCanvasTarget.getContext('2d');
    this.collectDrawingsFromComments();

    // Retrieve the last used color from libconnect and alter the picker
    const colorFromSettings = read(SETTINGS_COLOR_PICKER_KEY, null);
    if (colorFromSettings) {
      this.colorInputTarget.value = colorFromSettings;
    }
    // and then set it as the paint engine color
    this.paintEngine.color = this.colorInputTarget.value;

    this.eventManager = new EventListenerManager();
    this.eventManager.add(window, 'playerkeys.togglepaint', this.togglePaintContainer.bind(this));
    this.eventManager.add(window, 'keydown', (e) => {
      if (e.ctrlKey || e.metaKey || e.altKey || e.shiftKey) return;
      if (e.code !== "BracketRight" && e.code !== "BracketLeft") return;
      const firstHovered = document.querySelector("*:hover [brush-cursor-diameter]");
      console.log(firstHovered);
      if (!firstHovered) return;
      const inc = e.code === "BracketRight" ? 3 : -3;
      const evt = new CustomEvent("brushresize", {bubbles: true, detail: inc});
      firstHovered.dispatchEvent(evt);
    });
    this.eventManager.add(window, "atframe", (e) => {
      this.updateDrawingOnScreen(e);
    });
    this.eventManager.add(window, "comments.commentAdded", (e) => {
      this.paintEngine.clear();
      this.collectDrawingsFromComments();
      this.updateDrawingOnScreen(e);
    });

    this.paintEngine.setupEventListeners();
    this.updateUndoRedoButtonState();
    this.updateModeState();
    this.brushMenu = new BrushSelectorMenu({ menuContainer: this.brushSizesMenuTarget,
                                            optionNodes: this.brushSizeOptionTargets,
                                            selectAction: size => this.setBrushSize(size) });
  }

  disconnect() {
    this.eventManager.destroy();
  }

  updateUndoRedoButtonState() {
    if (this.mayUndo) {
      enable(this.undoBtnTarget);
    } else {
      disable(this.undoBtnTarget);
    }

    if (this.mayRedo) {
      enable(this.redoBtnTarget);
    } else {
      disable(this.redoBtnTarget);
    }
  }

  updateModeState() {
    if (this.paintEngine.mode === 'paint') {
      markActive(this.paintButtonTarget);
      markInactive(this.eraseButtonTarget);
    } else {
      markInactive(this.paintButtonTarget);
      markActive(this.eraseButtonTarget);
    }
  }
}
