import FileDropzoneController from "./file_dropzone_controller";
import * as ActiveStorage from 'activestorage';
import * as libdropfile from "libraries/libdropfile";
import Stam from "libraries/stam"

export default class extends FileDropzoneController {
  connect() {
    super.connect();

    // Define the UI states
    this.sm = new Stam("idle", "uploading").assume("idle");
    this.sm.permitTransition("idle", "uploading", "idle");
  }

  async acceptDroppedFiles(files) {
    if (libdropfile.containsInvalidFileTypes(files)) {
      alert(libdropfile.INVALID_FILE_TYPES_MSG);
      return;
    }

    this.sm.transitionTo("uploading");
    try {
      const filesPerFilename = {};
      files.forEach((f) => filesPerFilename[f.name] = f);

      const groupFilesResponse = await libdropfile.fetchWithProjectPrefix("group-files", "POST", {paths: Object.keys(filesPerFilename)});
      const detectedClips = await groupFilesResponse.json();

      if (detectedClips.length > 1) {
        throw new Error("Multiple sequences have been detected, so no idea how to do an upload with it")
      }
      const detectedClip = detectedClips.pop();

      // Partition the dropped files per actual dropped clip, by filename
      detectedClip.orderedFiles = detectedClip.frames.map((frameFilename) => filesPerFilename[frameFilename])

      // Start upload
      const shotId = this.element.dataset.shotId;
      console.log(`Uploading a version of shot ${shotId}`);

      return this.uploadSingleSequence(shotId, detectedClip);
    } finally {
      console.warn(`Transitioning back to idle`);
      this.sm.transitionTo("idle");
    }
  }

  async uploadSingleSequence(shotId, detectedSequence) {
    // Step 1 - create the new shot, versions, clips etc.

    let title = detectedSequence.pattern;
    let versionTitle = detectedSequence.versionTitle;

    const clipDescriptorResponse = await libdropfile.fetchWithProjectPrefix("prepare-version", "POST",
      {shot: {id: shotId, title, version: {title: versionTitle}}})

    if (!clipDescriptorResponse.ok) {
      throw new Error(`The response for clip creation was not OK: ${clipDescriptorResponse.status}`);
    }
    const clipDescriptor = await clipDescriptorResponse.json()

    // Step 2 - upload the actual files
    let progressEventsTo = this.element;
    let uploadPromises = libdropfile.createUploadPromises(detectedSequence.orderedFiles, progressEventsTo);
    let blobsArr = await Promise.all(uploadPromises); // wait until the actual upload completes
    let signedBlobIds = blobsArr.map((e) => e.signed_id);
    let signedClipId = clipDescriptor.clip.signed_id_for_attachment;

    // Step 3 - link uploaded blobs to the clip
    let attachResponse = await libdropfile.fetchWithProjectPrefix("attach-source-file-blobs", "PUT", {signed_clip_id: signedClipId, signed_blob_ids: signedBlobIds})
    if (!attachResponse.ok) {
      throw new Error(`The response for blob attachment was not OK: ${clipDescriptorResponse.status}`);
    }

    // Step 4 - reload the clip itself
    const selectorForReload = '[data-shot-id="' + shotId + '"]';
    const reflowEvent = new CustomEvent("reflow:selector", {bubbles: true, detail: selectorForReload});
    this.element.dispatchEvent(reflowEvent);
  }

  dismiss() {
    this.sm.transitionTo("idle");
  }
}
