import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [];

  toggleSidebar(evt) {
    if (evt.target.checked) {
      evt.target.parentNode.classList.add("sidebar-small");
      evt.target.parentNode.parentNode.classList.add("sidebar-reserve-small");
    } else {
      evt.target.parentNode.classList.remove("sidebar-small");
      evt.target.parentNode.parentNode.classList.remove("sidebar-reserve-small");
    }
  }
}
