import { Controller } from "stimulus";
import { EventListenerManager } from "libraries/libevents";
import Stam from "libraries/stam"

const knownModals = {};

// https://github.com/ai/nanoid/blob/master/index.js
function nanoid(size) {
  var url = '_~varfunctio0125634789bdegjhklmpqswxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  size = size || 21;
  var id = '';
  while (0 < size--) {
    id += url[Math.random() * 64 | 0];
  }
  return id;
}

function activateModalById(modalId) {
  let maybeController = knownModals[modalId];
  if (!maybeController) return;
  maybeController.activate();
}

// Allow triggering a modal by ID from anywhere in the document
document.activateModalById = activateModalById;

export default class extends Controller {
  connect() {
    this.modalId = this.element.getAttribute("id") || nanoid(12);
    this.element.setAttribute("data-modal-id", this.modalId);
    knownModals[this.modalId] = this;

    // Define the UI states
    this.sm = new Stam("hidden", "active").assume("hidden");
    this.sm.permitTransition("hidden", "active", "hidden");

    this.sm.havingEntered("active", () => this.element.parentNode.classList.remove("is-hidden"));
    this.sm.havingEntered("hidden", () => this.element.parentNode.classList.add("is-hidden"));

    this.sm.havingEntered("active", () => this.installCancellationHandlers());
    this.sm.havingEntered("hidden", () => this.removeCancellationHandlers());

    // Setup event handling for activation/cancellation
    this.em = new EventListenerManager();
  }

  activate() {
    this.sm.transitionTo("active");
  }

  close() {
    this.sm.transitionTo("hidden");
  }

  installCancellationHandlers() {
    this.em.add(window, "keyup", (evt) => {
      if (evt.keyCode === 27 ) { // escape key
        this.close();
      }
    });
  }

  removeCancellationHandlers() {
    this.em.destroy();
  }

  disconnect() {
    delete knownModals[this.modalId];
    this.em.destroy();
  }
}
