import { Controller } from "stimulus";
import { SimpleLinkMenu, getButtonSurface } from "libraries/libmenu";

export default class extends Controller {

  static targets = [ "menuContainer", "optionNode", "activateBtn", "titleInput" ];

  activateMenu(evt) {
    const target = getButtonSurface(evt)
    this.activatedId = target.dataset.id;
    this.menus[this.activatedId].activateMenu(evt);
  }


  connect() {
    this.menus = {};
    this.activateBtnTargets.forEach((btn, index) => {
      let id = btn.dataset.id;
      this.menus[btn.dataset.id] = new SimpleLinkMenu(
        { menuContainer: this.menuContainerTargets.find(el => el.dataset.id === id),
          optionNodes: Array.from(this.optionNodeTargets.filter(el => el.dataset.id === id)) }
      )
    })
  }


  renameInputHandler(evt) {
    if (evt.key === "Enter") {
      if (evt.target.value !== null && evt.target.value.trim() !== "") {
        console.log("Rename version title to: ", evt.target.value);
      }
      evt.target.value = "";
      evt.target.classList.add("is-hidden");
    } else if (evt.key === "Escape") {
      evt.target.value = "";
      evt.target.classList.add("is-hidden");
    }
  }

  rename(evt) {
    evt.preventDefault();

    console.log(evt.target);
    const titleInput = this.titleInputTargets.find(el => el.dataset.id === this.activatedId);
    titleInput.classList.remove("is-hidden");
    titleInput.addEventListener("keyup", this.renameInputHandler.bind(this));
    this.menus[this.activatedId].deactivateMenu();
  }

  disconnect() {
    Object.values(this.menus).forEach(menu => menu.cleanup());
  }
}
