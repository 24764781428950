require('../libraries/message-bus.js');
require('../libraries/message-bus-ajax.js');

MessageBus.callbackInterval = 1500;
MessageBus.start();

class Subscription {
  constructor(channel, handlerFn) {
    this._chan = channel.toString();
    this._handlerFn = handlerFn;
    // The last seen message ID (the MessageBus message ID, which is always monotonically increasing)
    // is stored in local storage.
    // In browser tests this value in local storage is saved, and therefore MessageBus will not receive
    // the notification as it thinks it already saw it (!)
    // To cheat MessageBus out of that behavior we will do a dirty and fetch "last relevant and the N previous"
    const retrieveN = -1;
    MessageBus.subscribe(this._chan, this._handlerFn, retrieveN);
  }
  cancel() {
    MessageBus.unsubscribe(this._chan, this._handlerFn);
  }
}

function pushSubscribe(channel, handlerFn) {
  return (new Subscription(channel, handlerFn));
}

export { pushSubscribe }
