import * as ActiveStorage from 'activestorage';
import { EventListenerManager } from "libraries/libevents";
import { getCurrentCSRFToken } from "libraries/libcsrf";
import { createClientBytesTask } from "libraries/libprogression";

export const VALID_FILE_MIMES = [ 'image/jpeg', 'image/png', 'video/mp4' ];
export const INVALID_FILE_TYPES_MSG = `Unsupported file types detected. Only ${VALID_FILE_MIMES.join(", ")} are valid for now`;

function fetchProjectURLPrefix() {
  return document.querySelector("meta[name=project-url-prefix]").getAttribute("content");
}

function getScopedDirectUploadURL() {
  return document.querySelector("meta[name=direct-upload-url]").getAttribute("content");
}

function fetchWithProjectPrefix(url, httpMethod, params) {
  const hh = {
    "Content-Type": ["application/json", "image/jpeg"],
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-Token": getCurrentCSRFToken()
  };
  const urlPrefix = fetchProjectURLPrefix();
  const postParams = {
    method: httpMethod,
    body: JSON.stringify(params),
    headers: hh
  };
  return fetch(`${urlPrefix}/${url}`, postParams);
}

function sumOverProperty(arr, propertyName) {
  return arr.map(elem => elem[propertyName]).reduce((prev, next) => prev + next, 0);
}

function containsInvalidFileTypes(files) {
  return files.some(file => !VALID_FILE_MIMES.includes(file.type))
}

function createUploadPromises(files, sendProgressEventsToElement) {
  const urlPrefix = fetchProjectURLPrefix();

  // We keep one task for the entire upload of a sequence or a group of files, and we measure in bytes
  const uploadTask = createClientBytesTask({description: "Uploading footage", bytesTotal: sumOverProperty(files, "size")});

  // Will fire upload progress events on the dropzone element. The listeners
  // on that element may deal with them in whichever way they prefer
  const dispatchProgressEvent = (perFile)=> {
    const uploadedBytes = sumOverProperty(perFile, "loaded");
    const ofTotalBytes = sumOverProperty(perFile, "total");

    const filesUploaded = perFile.filter(({loaded, total}) => loaded === total).length;
    const ofTotalFiles = perFile.length;

    uploadTask.setDelivered(uploadedBytes);
    const evt = new CustomEvent("libdropfile.uploadprogress", {detail: {uploadedBytes, ofTotalBytes, filesUploaded, ofTotalFiles}, bubbles: true});
    sendProgressEventsToElement.dispatchEvent(evt);
  }

  // Bookkeeping of upload progress per file
  const perFileProgress = files.map((f) => {
    return {loaded: 0, total: f.size}
  });

  // Assemble an array of upload promises that can all be waited on together
  const promises = files.map((f, i) => {
    const attachProgressEventListener = (request) => {
      request.upload.addEventListener("progress", (event) => {
        perFileProgress[i].loaded = event.loaded
        dispatchProgressEvent(perFileProgress);
      });
    };

    const delegate = {directUploadWillStoreFileWithXHR: attachProgressEventListener};
    const upload = new ActiveStorage.DirectUpload(f, getScopedDirectUploadURL(), delegate);

    return new Promise((resolve, reject) => {
      upload.create((error, blob) => error ? reject(error) : resolve(blob));
    });
  });

  // Once all the promises are either rejected or fulfilled complete the task
  Promise.allSettled(promises).then(() => uploadTask.markCompleted());

  return promises;
}

export { containsInvalidFileTypes, createUploadPromises, fetchProjectURLPrefix, fetchWithProjectPrefix };
