import { EventListenerManager } from "libraries/libevents";
import Stam from "./stam";

const FOCUS_DROPZONE_CLASS = "libdropzone-will-accept-drop";

const IDLE = "idle";
const BYPASS = "bypass";
const DRAGGING = "dragging";

// Allows one to check if the drag&drop event carries any files or not.
// Note that it is expensive to call, so we try to call it only once.
// It can be converted into this incantation too:
// https://stackoverflow.com/a/8494918/153886
function eventCarriesFiles(evt) {
  if (evt.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let item of evt.dataTransfer.items) {
      if (item.kind === "file") {
        return true;
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (let file of evt.dataTransfer.files) {
      return true;
    }
  }
  return false;
}

function removeDragDataFromEvent(evt) {
  if (evt.dataTransfer.items) {
    // Use DataTransferItemList interface to remove the drag data
    evt.dataTransfer.items.clear();
  } else {
    // Use DataTransfer interface to remove the drag data
    evt.dataTransfer.clearData();
  }
}

// Collects all the files contained in the event into an array
function collectFilesFromEvent(evt) {
  const fileList = [];
  if (evt.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let item of evt.dataTransfer.items) {
      // If dropped items aren't files, reject them
      if (item.kind === "file") {
        fileList.push(item.getAsFile());
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (let file of evt.dataTransfer.files) {
      fileList.push(file);
    }
  }
  return fileList;
}

const fileDragState = new Stam(IDLE, BYPASS, DRAGGING).assume(IDLE);
fileDragState.debug = true;
fileDragState.permitTransition(IDLE, DRAGGING, IDLE);
fileDragState.permitTransition(DRAGGING, IDLE); // When drag&drop is canceled
fileDragState.permitTransition(IDLE, BYPASS, IDLE); // When a drag and drop is happening that is not file-related

// The number of times the "dragenter" event was fired on the document,
// will be decremented for every "dragleave" event.
let viewportDragenterCount = 0;

// Modules are evaluated globally, so we can add these listeners
// onto the document and not be afraid they are going to be defined twice.
document.addEventListener('dragenter', (evt) => {
  viewportDragenterCount++;
  if (viewportDragenterCount == 1) { // Start of the drag&drop sequence
    if (eventCarriesFiles(evt)) {
      fileDragState.transitionTo(DRAGGING); // We are dealing with files
    } else {
      fileDragState.transitionTo(BYPASS); // We are dealing with non-files, do not monitor until our operation ends
    }
  }
}, {passive: true});

document.addEventListener('dragleave', (evt) => {
  viewportDragenterCount--;
  if (viewportDragenterCount == 0) {
    fileDragState.transitionTo(IDLE);
  }
}, {passive: true});

// When another library accepts "drop" - like reorderable -
// for a different type of dataTransfer - we will be in the BYPASSED
// state, which we need to deactivate to be able to accept drags again.
document.addEventListener('drop', (evt) => {
  viewportDragenterCount--;
  if (viewportDragenterCount == 0) {
    fileDragState.transitionTo(IDLE);
  }
});

// Wrapper for event handlers which will only activate those event handlers if we are currently
// observing the dragging of files
function duringFileDragOnly(eventHandlerFn) {
  return function(event) {
    if (fileDragState.state === DRAGGING) {
      return eventHandlerFn(event);
    } else {
      return true;
    }
  }
}

function preventDefault(e) {
  return e.preventDefault();
}

function createEventManager(specificDropzone) {
  const elm = new EventListenerManager();

  let entryCountForElement = 0;

  const didEnter = ()=> {
    entryCountForElement++;
    if (entryCountForElement === 1) {
      const defocusedEvent = new CustomEvent('libdropzone:focus', {bubbles: true});
      specificDropzone.dispatchEvent(defocusedEvent);
      specificDropzone.classList.add(FOCUS_DROPZONE_CLASS);
    }
  }

  const didLeave = ()=> {
    entryCountForElement--;
    if (entryCountForElement < 1) {
      const defocusedEvent = new CustomEvent('libdropzone:blur', {bubbles: true});
      specificDropzone.dispatchEvent(defocusedEvent);
      specificDropzone.classList.remove(FOCUS_DROPZONE_CLASS);
    }
  }

  const didDrop = (evt) => {
    const detail = collectFilesFromEvent(evt);
    removeDragDataFromEvent(evt);
    evt.preventDefault();

    // In this WebComponent setup:
    // https://github.com/GoogleChromeLabs/file-drop#readme
    // a custom event class is set up which can carry a custom property
    // called "files". It seems however that a standard CustomEvent can't be
    // inherited from unless you are dealing with a shadow DOM, and I don't feel like
    // finding out how to add a custom property to an event the right way.
    const customDropEvent = new CustomEvent('libdropzone:filesdrop', {detail, bubbles: true});
    specificDropzone.dispatchEvent(customDropEvent);
    specificDropzone.classList.remove(FOCUS_DROPZONE_CLASS);
  }

  // Dropzone only manages focus and will not cancel or preventDefault
  elm.add(specificDropzone, 'dragenter', duringFileDragOnly(didEnter), {passive: true});
  elm.add(specificDropzone, 'dragleave', duringFileDragOnly(didLeave), {passive: true});

  // The dragover+drop cannot be passive as we need to preventDefault on BOTH
  // to prevent the browser from performing the default action.
  // Assigning the "drop" only is not sufficient.
  elm.add(specificDropzone, "dragover", duringFileDragOnly(preventDefault));
  elm.add(specificDropzone, 'drop', duringFileDragOnly(didDrop));

  return elm;
}

export { createEventManager };
