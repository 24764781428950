/*

stam = new Stam("opening", "running", "closing").assume("opening")
stam.permitTransition("opening", "running")

stam.beforeEntering("running", ()=> console.log("Entering running"))
stam.havingLeft("opening", ()=> console.log("Leaving opening"))
stam.havingLeft("zopening", ()=> console.log("Should never fire"))

stam.transitionTo("running")

*/
class Stam {
  constructor(...permittedStates) {
    this.permittedStates = new Set(permittedStates);
    this.permittedTransitions = new Set([]);
    this.unknown = Symbol();
    this.state = this.unknown;
    this.callbacks = {};
    this.inTransition = false;
    this.debug = false;
    this.ignoreRepeatedTx = false;
  }

  // Defines what should happen if the state machine is asked
  // to transition to the state it is already in. Imagine you
  // have a toolbar with two states: "visible" and "hidden".
  // You call transitionTo("visible") and Stam goes into that state.
  // You then call transitionTo("visible") again, since your visibility
  // activation is on a timer, but your state machine already is "visible".
  // What happens next depends on the state repeat behavior.
  //
  // * `false` (default) - will allow the repeat state activation to take place
  //   if there is a defined transition, in the example case permitTransition("visible", "visible").
  //   All the callbacks are going to be dispatched (whenLeaving, afterLeaving and so forth).
  //   That is the default behavior.
  // * `true` - will allow the transitionTo("visible") call but if the machine already is in
  //   that state the call will be ignored. No callbacks will be executed.
  set ignoreRepeatedTransitions(newValue) {
    this.ignoreRepeatedTx = newValue ? true : false;
    return this.ignoreRepeatedTx;
  }
  get ignoreRepeatedTransitions() {
    return this.ignoreRepeatedTx;
  }

  assume(initialState) {
    if (!this.permittedStates.has(initialState)) {
      throw `${this} does not have a known state of ${state}`;
    }
    this.state = initialState;
    return this;
  }

  addState(...stateNames) {
    for (let stateName of stateNames) {
      this.permittedStates.add(stateName);
    }
    return this;
  }

  permitTransition(fromState, toState, ...subsequentStates) {
    if (!this.permittedStates.has(fromState)) {
      throw `${this} does not have a known state of ${fromState}`;
    }
    if (!this.permittedStates.has(toState)) {
      throw `${this} does not have a known state of ${toState}`;
    }
    this.permittedTransitions.add(JSON.stringify([fromState, toState]));

    // If only two states were passed - return, otherwise continue
    // defininig them recursively
    if (subsequentStates.length > 0) {
      const nextStateInSequence = subsequentStates.shift();
      this.permitTransition(toState, nextStateInSequence, ...subsequentStates);
    }

    return this;
  }

  transitionTo(newState) {
    // If we are entering the state we already are in
    if (this.ignoreRepeatedTx && newState === this.state) {
      return this;
    }

    if (this.state === this.unknown) {
      throw `${this} unable to transition to ${newState} as initial state has not been assumed yet. Call Stam#assume(stateName) first`;
    }
    if (this.inTransition) {
      throw `${this} is transitioning at the moment, possible data race`;
    }
    try {
      this.inTransition = true;
      const prevState = this.state;
      const pendingTransition = JSON.stringify([prevState, newState]);
      if (!this.permittedTransitions.has(pendingTransition)) {
        throw `${this} has no transition defined for ${pendingTransition}`;
      }

      this._fireEvents("beforeLeaving", prevState);
      this._fireEvents("beforeEntering", newState);
      this._fireEvents("beforeTransitioning", prevState, newState);

      if (this.debug) {
        console.debug(`transition: ${prevState} -> ${newState}`);
      }
      this.state = newState;

      this._fireEvents("havingTransitioned", prevState, newState);
      this._fireEvents("havingLeft", prevState);
      this._fireEvents("havingEntered", newState);
      return this;
    } finally {
      this.inTransition = false;
    }
  }

  beforeTransitioning(fromState, toState, fn) {
    this._addCb("beforeTransitioning", fromState, toState, fn);
    return this;
  }

  havingTransitioned(fromState, toState, fn) {
    this._addCb("havingTransitioned", fromState, toState, fn);
    return this;
  }

  havingLeft(previousState, fn) {
    this._addCb("havingLeft", previousState, undefined, fn);
    return this;
  }

  havingEntered(newState, fn) {
    this._addCb("havingEntered", newState, undefined, fn);
    return this;
  }

  beforeEntering(newState, fn) {
    this._addCb("beforeEntering", newState, undefined, fn);
    return this;
  }

  beforeLeaving(newState, fn) {
    this._addCb("beforeLeaving", newState, undefined, fn);
    return this;
  }

  _fireEvents(evtName, fromEvt, toEvt) {
    const k = JSON.stringify([evtName, fromEvt, toEvt]);
    if (!this.callbacks[k]) return;
    for (let cbFn of this.callbacks[k]) {
      cbFn(fromEvt, toEvt);
    }
  }

  _addCb(eventName, fromState, toState, fn) {
    if (!this.permittedStates.has(fromState)) {
      throw `${this} does not support state ${fromState}`;
    }
    if (toState && !this.permittedStates.has(toState)) {
      throw `${this} does not support state ${toState}`;
    }

    const k = JSON.stringify([eventName, fromState, toState]);
    if (!this.callbacks[k]) this.callbacks[k] = [];
    this.callbacks[k].push(fn);
  }
}

export default Stam