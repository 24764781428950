import { Controller } from "stimulus"
import { throttle, debounce } from 'throttle-debounce';
import { EventListenerManager } from "libraries/libevents";

const SCRUB_INDICATOR_COLOR_FRONT = "#87A6FF";
const SCRUB_INDICATOR_COLOR_BACK = "#3B4850";

// Implements behaviors for scrubbing through a clip icon
export default class extends Controller {
  static targets = [ "dragslider", "scrubPositionIndicator"]

  connect() {
    // Handlers get torn down in disconnect() so have to be set up even
    // if we are not continuing with our setup (if there is only 1 frame say)
    this.handlers = new EventListenerManager();
    this.numFrames = parseInt(this.element.dataset.filmstripFrameCount) || 1;

    this.filmstripImage = new Image();
    this.filmstripImage.crossOrigin = 'anonymous';
    this.filmstripImage.onload = () => this.renderInitialFrame();
    this.filmstripImage.src = this.element.dataset.filmstripUrl;
    this.canvas = this.element.querySelector('canvas');

    this.handlers.add(this.dragsliderTarget, "mouseenter", this.handleHoverEnterSlider.bind(this));
    this.handlers.add(this.dragsliderTarget, "mouseleave", this.handleHoverLeaveSlider.bind(this));
    this.handlers.add(this.dragsliderTarget, "dragslider:tap", this.handleTappedSlider.bind(this));
    this.handlers.add(this.dragsliderTarget, "dragslider:moved", this.handleMovedSlider.bind(this));
    this.handlers.add(this.dragsliderTarget, "dragslider:released", this.handleReleasedSlider.bind(this));
  }

  // Called once the filmstrip image is loaded
  renderInitialFrame() {
    // The dimensions of the canvas are preconfigured to the right size,
    // so no need to do anything - and moreover, if we DO something we might trigger
    // object-fit bugs
    //
    // The initial frame to display may be stored in the dataset of the element,
    // since when we drag-reorder a clip the Stimulus controller gets disconnected.
    // When it reconnects, it can recover this bit of state.
    const initialFrameIdx = parseInt(this.element.dataset.displayedFilmstripFrame) || 0;
    this.displayFrameAtIdx(initialFrameIdx);
    this.updateScrubIndicatorFn(initialFrameIdx);
  }

  displayFrameAtIdx(frameIndex) {
    const singleTileSrcWidth = this.filmstripImage.naturalWidth / this.numFrames;
    const singleTileSrcHeight = this.filmstripImage.naturalHeight;

    const ctx = this.canvas.getContext('2d');
    // Black out the canvas
    ctx.beginPath();
    ctx.rect(0, 0, this.canvas.width, this.canvas.height);
    ctx.fillStyle = 'black';
    ctx.fill();

    // Draw the image, fitting it into the container
    ctx.drawImage(this.filmstripImage,
      frameIndex * singleTileSrcWidth, 0, // x offset, y offset in source
      singleTileSrcWidth, singleTileSrcHeight, // tile width, tile height in source
      0, 0, // target x, y
      this.canvas.width, this.canvas.height // width/height of the patched area, fill the entire canvas here
    );
    // Persist the frame number displayed
    this.element.dataset.displayedFilmstripFrame = frameIndex;
  }

  updateScrubIndicatorFn(toFrameIdxZeroBased) {
    // If we want to display the bar filled for 1 frame already, and have it filled completely
    // on the last frame, we need the following algorithm:
    //  const fi = toFrameIdxZeroBased + 1;
    //  const ratio = fi / Math.max(this.numFrames - 1, 1);
    // For now, use a slightly different version - that does not fill anything with blue
    // when parked on the very first frame
    const ratio = toFrameIdxZeroBased / Math.max(this.numFrames - 1, 1);
    const perc = Math.ceil(ratio * 100);
    // CSS gradient definition
    // http://colorzilla.com/gradient-editor/#7293de+50,d8d8d8+50
    // Dynamically create a linear gradient declaration using the percentage
    const gradientPropertyValue = `linear-gradient(to right, ${SCRUB_INDICATOR_COLOR_FRONT} ${perc}%, ${SCRUB_INDICATOR_COLOR_BACK} ${perc}%)`;
    this.scrubPositionIndicatorTarget.style.backgroundImage = gradientPropertyValue;
  }

  handleHoverLeaveSlider(e) {
    if (e.buttons === 0) {
      this.scrubPositionIndicatorTarget.style.visibility = "hidden";
    }
  }

  handleHoverEnterSlider(e) {
    if (e.buttons === 0) {
      this.scrubPositionIndicatorTarget.style.visibility = "visible";
    }
  }

  handleTappedSlider(e) {
    // Cache the element dimension so that we do not have to "pull" at the DOM for every mousemove event
    this.elementWidth = e.target.getBoundingClientRect().width;
    // Make the scrub indicator visible
    this.scrubPositionIndicatorTarget.style.visibility = "visible";
    // Save the frame we are starting the drag interaction from,
    // since we are going to be moving relative to it
    this._frameIdxAtInteractionStart = parseInt(this.element.dataset.displayedFilmstripFrame) || 0;
  }

  handleMovedSlider(e) {
    // The detail of the event is the offset in pixels between where the interaction
    // started and current. So the interaction could have started anywhere.
    const pxDelta = e.detail;

    // We want to be able to allow scrolling back and forth throughout the clip with roughly
    // 3 widths of the element - then if you have a lot of frames the movement will have
    // to be very fine, but if you have a few it will have to be coarse
    const pxEntireScrubTrack = this.elementWidth * 3;
    const pxTraversalPerFrame = pxEntireScrubTrack / this.numFrames;
    const deltaFrames = Math.round(pxDelta / pxTraversalPerFrame);

    const unclampedIdx = this._frameIdxAtInteractionStart + deltaFrames;
    const clampedIdx = Math.min(Math.max(0, unclampedIdx), this.numFrames - 1);
    this.displayFrameAtIdx(clampedIdx);
    this.updateScrubIndicatorFn(clampedIdx);
  }

  handleReleasedSlider(e) {
    this.scrubPositionIndicatorTarget.style.visibility = "hidden";
  }

  disconnect() {
    this.handlers.destroy();
  }
}
