import merge from "deepmerge";
import { Controller } from "stimulus"
import { createReorderableBehavior } from "libraries/libreorderable"
import { getCurrentCSRFToken } from "libraries/libcsrf";

function projectFetch(projectUrl, fetchOptions) {
  const defaultOptions = {
    headers: {
      "Content-Type": ["application/json", "text/html", "image/jpeg"],
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": getCurrentCSRFToken(),
    }
  }
  // baseUrl contains the hostname, scheme etc.
  const baseUrl = document.querySelector("meta[name=project-url-prefix]").getAttribute("content");
  const mergedFetchOptions = merge(defaultOptions, fetchOptions);
  return fetch(`${baseUrl}${projectUrl}`, mergedFetchOptions);
}

const SHOT_NODES_SELECTOR = ".m-shot-card[data-shot-id]";

export default class extends Controller {
  static targets = [ "list" ]

  connect() {
    this.sortable = createReorderableBehavior(this.listTarget, {draggableItemSelector: SHOT_NODES_SELECTOR});
  }

  reorderStarted(evt) {
  }

  applyReorder(evt) {
    evt.stopPropagation();

    const shotIds = (shotNodes) => {
      return shotNodes.map((el) => el.dataset.shotId)
    }

    const shotNodes = Array.from(this.listTarget.querySelectorAll(SHOT_NODES_SELECTOR));
    const pre = shotIds(shotNodes)
    const reorderedShotNodes = evt.detail.map((i) => shotNodes[i]);
    const post = shotIds(reorderedShotNodes)

    // Shoot of a request to change them on the server
    const body = JSON.stringify({pre, post});
    projectFetch("/shot-ordering", {method: 'PUT', body})
      .then((response) => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error(`Server replied with ${response.status}`);
        }
      })
      .then(() => {
        // and finally alter the DOM with the new order of elements,
        // adding after the first item in the list (dropzone item).
        reorderedShotNodes.forEach((el) => {
          this.listTarget.appendChild(el);
        });
      });
  }

  disconnect() {
    this.sortable.destroy();
  }
}
