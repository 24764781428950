// This library is kept separate as it only concerns itself with
// handling keyboard events. Its primary goal however is to
// determine whether the shortcuts should be treated as
// player shortcuts or permitted to "drop through" to
// other DOM nodes

const KBD_EVENT_PLAY_PAUSE = new CustomEvent('playerkeys.playpause', {bubbles: true});
const KBD_EVENT_PAUSE = new CustomEvent('playerkeys.pause', {bubbles: true});
const KBD_EVENT_ACCEL_FWD = new CustomEvent('playerkeys.jogforward', {bubbles: true});
const KBD_EVENT_ACCEL_BWD = new CustomEvent('playerkeys.jogbackward', {bubbles: true});
const KBD_EVENT_FRAME_FWD = new CustomEvent('playerkeys.frameforward', {bubbles: true});
const KBD_EVENT_FRAME_BACK = new CustomEvent('playerkeys.framebackward', {bubbles: true});
const KBD_EVENT_FULLSCREEN_TOGGLE = new CustomEvent('playerkeys.fullscreen', {bubbles: true});
const KBD_EVENT_TOGGLE_LOOP = new CustomEvent('playerkeys.toggleloop', {bubbles: true});
const KBD_EVENT_TOGGLE_PAINT = new CustomEvent('playerkeys.togglepaint', {bubbles: true});
const KBD_EVENT_TOGGLE_COMMENT_FORM = new CustomEvent('playerkeys.toggleCommentForm', {bubbles: true});

// These elements should receive keyboard input and "steal" keyboard focus from the player
const FOCUSABLE_ELEMENTS_SELECTOR = "button, input, textarea, select, label, a, [tabindex], [contenteditable], [role]";
const BUTTONS_IN_PLAYER_BUTTON_BAR_SELECTOR = ".player-button-bar button";

const LEFT_ARR = 37;
const RIGHT_ARR = 39;
const SPACE = 32;
const ENTER = 13;
const J = 74;
const K = 75;
const L = 76;
const F = 70;
const O = 79;
const P = 80;
const T = 84;
const keymap = {};

keymap[RIGHT_ARR] = KBD_EVENT_FRAME_FWD;
keymap[LEFT_ARR] = KBD_EVENT_FRAME_BACK;
keymap[SPACE] = KBD_EVENT_PLAY_PAUSE;
keymap[ENTER] = KBD_EVENT_PLAY_PAUSE;
keymap[J] = KBD_EVENT_ACCEL_BWD;
keymap[K] = KBD_EVENT_PAUSE;
keymap[L] = KBD_EVENT_ACCEL_FWD;
keymap[F] = KBD_EVENT_FULLSCREEN_TOGGLE;
keymap[O] = KBD_EVENT_TOGGLE_LOOP;
keymap[P] = KBD_EVENT_TOGGLE_PAINT;
keymap[T] = KBD_EVENT_TOGGLE_COMMENT_FORM;

// Some of the shortcuts we need to handle are key combinations -
// for example holding "K" while pressing "L" means "frame forward"
// so we need to improvise a held keys map to detect those.
const keysHeld = {};
function maskKey(e) {
  keysHeld[e.keyCode] = true;
}
function unmaskKey(e) {
  keysHeld[e.keyCode] = false;
}
window.addEventListener('keydown', maskKey);
window.addEventListener('keyup', unmaskKey);

function playerKeydownHandler(e) {
  // None of our shortcuts should override keyboard shortcuts
  // of the browser/OS, so anything that uses modifier
  // keys should be passthru. For example, we do attach to J
  // for "jog backwards", but on Chrome Cmd+Opt+J is "open developer tools".
  // We have no business touching these.
  if (e.altKey || e.metaKey || e.ctrlKey) {
    return true;
  }

  // Builtin "focusable" elements - elements that accept keyboard
  // input - also have priority. If you are inside a textarea
  // editing your text the last thing you expect when typing "space"
  // is for some video playback to start
  if (document.activeElement.matches(FOCUSABLE_ELEMENTS_SELECTOR) && !document.activeElement.matches(BUTTONS_IN_PLAYER_BUTTON_BAR_SELECTOR)) {
    return true;
  }

  // Handle compound shortcuts first. We have two of these - JK and KL
  // for frame forward/back.
  if (keysHeld[K] && e.keyCode === L) {
    window.dispatchEvent(KBD_EVENT_FRAME_FWD);
    return e.preventDefault();
  }
  if (keysHeld[K] && e.keyCode === J) {
    window.dispatchEvent(KBD_EVENT_FRAME_BACK);
    return e.preventDefault();
  }

  // then single-button ones
  const maybeEvent = keymap[e.keyCode];
  if (maybeEvent) {
    window.dispatchEvent(maybeEvent);
    return e.preventDefault();
  }

  return true;
}

export { playerKeydownHandler };
