const PLAY_EVENT = new CustomEvent("libausync.play", {bubbles: true});
const PAUSE_EVENT = new CustomEvent("libausync.pause", {bubbles: true});
const LOADED_EVENT = new CustomEvent("libausync.audioloaded", {bubbles: true});

class AudioTimeSource {
  constructor() {
    this._auContext = new AudioContext();
    this._auContext.suspend();
    this._seekOffset = 0.0;
    this._bufferSourceNode = null;
    this._audioRdy = true; // Assume we wont be loading any audio yet
    this._eventBus = new EventTarget();
    this.isScrubbing = false;
    // Cheat and emit an event for loaded, but only if _audioRdy is still true
    setTimeout(() => {
      if (this._audioRdy) {
        this._eventBus.dispatchEvent(LOADED_EVENT);
      }
    }, 50);
  }

  addEventListener(...any) {
    this._eventBus.addEventListener(...any);
  }

  removeEventListener(...any) {
    this._eventBus.removeEventListener(...any);
  }

  // Used to initialize a new buffer source and gain and connect it to the context destination.
  initBufferSource() {
    this._bufferSourceNode = this._auContext.createBufferSource();
    this._bufferSourceNode.buffer = this._buffer;
    this._gainNode = this._auContext.createGain();
    this._bufferSourceNode.connect(this._gainNode);
    this._gainNode.connect(this._auContext.destination);
  }

  // Fills the buffer source with the file data loaded.
  loadSound(url) {
    if (!url) return;
    this._audioRdy = false;
    let request = new XMLHttpRequest();
    request.open("GET", url, true);
    request.responseType = "arraybuffer";
    request.onload = () => {
      this._auContext.decodeAudioData(request.response, (buffer) => {
        this._buffer = buffer;
        this._audioRdy = true;
        this._eventBus.dispatchEvent(LOADED_EVENT)
      });
    }
    request.send();
  }

  playOrPause() {
    if (this._auContext.state === "suspended") {
      return this.play();
    } else {
      return this.pause();
    }
  }

  play() {
    if (this._auContext.state === "running") return;
    this._auContext.resume();
    if (this._buffer) {
      this.initBufferSource();
      this._bufferSourceNode.start(0, this.currentTime);
    }
    this._eventBus.dispatchEvent(PLAY_EVENT);
    window.dispatchEvent(PLAY_EVENT);
  }

  pause() {
    if (this._auContext.state === "suspended") return;
    this._auContext.suspend();
    if (this._bufferSourceNode) {
      this._bufferSourceNode.stop(0);
    }
    this._eventBus.dispatchEvent(PAUSE_EVENT);
    window.dispatchEvent(PAUSE_EVENT);
  }

  get state() {
    return this._auContext.state;
  }

  get isAudioReady() {
    return this._audioRdy;
  }

  set currentTime(absoluteTimeSeconds) {
    this._seekOffset = (this._auContext.currentTime - absoluteTimeSeconds);
    if (this._bufferSourceNode && this._auContext.state === "running") {
      this._bufferSourceNode.stop(0);
      this.initBufferSource();
      this._bufferSourceNode.start(0, this.currentTime);
    }
    return this.currentTime;
  }

  get paused() {
    return this._auContext.state === "suspended";
  }

  get currentTime() {
    return this._auContext.currentTime - this._seekOffset;
  }

  destroy() {
    this.pause();
    this._auContext.close();
    this._bufferSourceNode = null;
    this._buffer = null;
    this._eventBus = null; // Hope the browser GC's the EventTarget correctly
  }
}

class AudioVideoSync {
  constructor(auClock, renderFn) {
    let lastSeenClockTime = auClock.currentTime;
    let lastFrameTriggered = 0;
    const auSyncLoop = () => {
      const clockTimeNow = auClock.currentTime;

      // Drawing takes some time, so by the time the screen gets refreshed
      // and the viewer sees the frame displayed, some time is going to pass.
      // We therefore tell the player to render not the frame that would be
      // show at clockTimeNow but the frame that would be shown at clockTimeNow + timeToRender.
      // We only need that delay though _when we are actually playing_,
      // otherwise we will try to "predict" which frame needs to be shown based on user interaction
      // and will be wrong, most of time
      const lookahead = (!auClock.paused && !auClock.isScrubbing) ? (clockTimeNow - lastSeenClockTime) : 0;
      lastSeenClockTime = clockTimeNow;
      renderFn(clockTimeNow + lookahead, auClock.paused);

      // Schedule ourselves to run at the next frame as well
      this.syncLoopAnimationFrameRequest = requestAnimationFrame(auSyncLoop);
    };
    auSyncLoop();
  }

  destroy() {
    cancelAnimationFrame(this.syncLoopAnimationFrameRequest);
  }
}

export {AudioTimeSource, AudioVideoSync};
