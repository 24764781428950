import { Controller } from "stimulus";
import { EventListenerManager } from "libraries/libevents";
import { SimpleLinkMenu } from "libraries/libmenu";

// Controller for "generic" popover menus which
// work roughly like a <select /> element

export default class PopoverController extends Controller {
  static targets = ["options"];

  connect() {
    const optionNodes = this.optionsTarget.querySelectorAll('.option');
    this.menu = new SimpleLinkMenu({menuContainer: this.optionsTarget, optionNodes})
  }

  disconnect() {
    this.menu.cleanup();
  }
  // gets called when the "ellipsis" button gets pressed
  // but is not yet released
  activate(evt) {
    this.menu.activateMenu(evt);
  }
}
