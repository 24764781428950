import { Controller } from "stimulus";
import { hideModal } from 'libraries/libmodal';

export default class extends Controller {
  static targets = ['example'];

  changeColor() {
    this.exampleTarget.classList.toggle("green");
    // hideModal();
  }
}

