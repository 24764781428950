// Gratuitousy lifted from https://gist.github.com/jessetan/2a2fece59d9afa9461cc

function secondsToTimecodeObject(seconds, framerate) {
  var f = Math.floor((seconds % 1) * framerate);
  var s = Math.floor(seconds);
  var m = Math.floor(s / 60);
  var h = Math.floor(m / 60);
  m = m % 60;
  s = s % 60;

  return {h: h, m: m, s: s, f: f, fps: framerate};
}

function framesToTimecodeObject(frames, framerate) {
  const seconds = frames / framerate;
  return secondsToTimecodeObject(seconds, framerate);
}

/** Pretty print SMPTE timecode JSON object */
function formatTimecodeObject(timecode) {
  // I heard there is an AMAZING npm module called
  // "leftpad" just for this...
  if (timecode.h < 10) { timecode.h = "0" + timecode.h; }
  if (timecode.m < 10) { timecode.m = "0" + timecode.m; }
  if (timecode.s < 10) { timecode.s = "0" + timecode.s; }
  if (timecode.f < 10) { timecode.f = "0" + timecode.f; }

  return timecode.h + ":" + timecode.m + ":" + timecode.s + ":" + timecode.f;
}

function formatTimecodeFromSeconds(seconds, framerate) {
  return formatTimecodeObject(secondsToTimecodeObject(seconds, framerate));
}

function formatTimecodeFromFrames(frames, framerate) {
  return formatTimecodeObject(framesToTimecodeObject(frames, framerate));
}

export { secondsToTimecodeObject, formatTimecodeObject, formatTimecodeFromSeconds, formatTimecodeFromFrames };