import { Controller } from "stimulus";
import { subscribe, read } from "libraries/libconnect";
import { formatTimecodeFromFrames } from "libraries/libtimecode";

const FRAMES_TIMECODE_DISPLAY_PREFERENCE = "frames-timecode-display-preference";

export default class extends Controller {
  connect() {
    this.sub = subscribe(FRAMES_TIMECODE_DISPLAY_PREFERENCE, this.update.bind(this));
    this.update(this.sub.read("timecode"));
  }

  update(fmt = "timecode") {
    const fps = parseFloat(this.element.dataset.framerate || 24.0);
    const frameNumber = parseInt(this.element.dataset.frameNumber || 0);
    const sampleTimecode = formatTimecodeFromFrames(0, fps); // for sizing the frames string
    
    if (fmt === "timecode") {
      this.element.textContent = formatTimecodeFromFrames(frameNumber, fps);
    } else {
      this.element.textContent = `F${frameNumber + 1}`;
    }
  }

  disconnect() {
    this.sub.cancel();
  }
}
