// Tiny functions and utilities that are used all over


// Get UV coordinates within the bounding box of given element,
// used for elements like sliders and scrub bars
function eventUV(pointerEvent, element){
  // Compute all the transforms and offsets
  const rect = element.getBoundingClientRect();

  // We use clientX instead of pageX because
  // pageX changes when the document is scrolled down from the 0 scroll.
  const x = (pointerEvent.clientX - rect.left);
  const y = (pointerEvent.clientY - rect.top);
  let u = x / rect.width;
  let v = y / rect.height;
  if (isNaN(u)) u = 0.5;
  if (isNaN(v)) v = 0.5;

  return {u, v};
}

// Combines the given functions into one function.
// When called, that function will call every function
// with the given arguments, and return
// an Array of the values those functions returned.
function mux(...functions) {
  return function(...args) {
    return functions.map((fn) => fn(...args));
  }
}

// Compute the centroid of an element in _page_ coordinates
// (from the top-left of the page, accounting for the scroll).
// We need to account for the scroll here because it is not only possible,
// but actually _used_ by many that with long lists you can scroll while
// you drag - pick an item, focus over the destination drop area and then scroll
// using the wheel to "reposition" the area for your drop. Check this out, really -
// it works like this in native macOS controls since ages.
//
// Also, one of the very good indications of web-engine based apps posing as native:
// scroll-during-drag not working correctly. We will not be like those apps.
function computeCentroid(element) {
  const rect = element.getBoundingClientRect();
  const viewportX = (rect.left + rect.right) / 2;
  const viewportY = (rect.top + rect.bottom) / 2;
  return {x: viewportX + window.scrollX, y:  viewportY + window.scrollY};
}

function distanceBetweenCursorAndPoint(mouseevt, centroid) {
  return Math.hypot(centroid.x - mouseevt.clientX - window.scrollX, centroid.y - mouseevt.clientY - window.scrollY);
}

// Quick array comparison (linear time)
function arraysEq(a, b) {
  if (a.length !== b.length) return false;
  for (const [i, va] of a.entries()) {
    if (va !== b[i]) {
      return false;
    }
  }

  return true;
}

// Memoize based on argument values.
// Given a function, it will return it wrapped. When you
// call the wrapper the first time, the given function will
// be called and its return value will be cached.
// When called the next time, it will compare the first
// given argument to the value
// of that argument during the last invocation. If the
// value is the same, the wrapped function is not going to
// be called and the memoized return value is going to be
// returned instead. If the argument does have a different
// value from the last time the function was called, the
// wrapped function is going to be called again.
function withLazyUpdate(...givenFunctions) {
  let previousArguments = [Symbol()];
  let returnValues = [];
  let muxFn = mux(...givenFunctions);
  return function(...nextArguments) {
    if (!arraysEq(previousArguments, nextArguments)) {
      previousArguments = nextArguments;
      returnValues = muxFn(...nextArguments);
    }
    if (givenFunctions.length == 1) {
      return returnValues[0];
    } else {
      return returnValues;
    }
  }
}

function clamp(min, value, max) {
  if(isNaN(value)) {
    throw "Unable to clamp a NaN";
  }
  return Math.max(min, Math.min(value, max))
}

function zeroPad(number, digits) {
  return Array(Math.max((digits - String(number).length) + 1, 0)).join(0) + number;
}

function isOutsideRect(x, y, rect) {
  return (x < rect.left || x > rect.right || y < rect.top || y > rect.bottom);
}

export {eventUV, mux, withLazyUpdate, clamp, zeroPad, isOutsideRect, computeCentroid, distanceBetweenCursorAndPoint };
