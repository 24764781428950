import FileDropzoneController from "./file_dropzone_controller";
import * as ActiveStorage from 'activestorage';
import * as libdropfile from "libraries/libdropfile";
import Stam from "libraries/stam"
import { showModal } from 'libraries/libmodal';

export default class extends FileDropzoneController {
  static targets = [ "dialog", "dropzone" ]

  initialize() {
    // to save the dialog target before the modal gets moved to top of dom
    this.dialog = this.dialogTarget;
    this.dropzone = this.dropzoneTarget;
  }

  connect() {
    super.connect();

    // Define the UI states
    this.sm = new Stam("idle", "confirmation", "uploading").assume("idle");
    this.sm.permitTransition("idle", "confirmation");
    this.sm.permitTransition("confirmation", "uploading");
    this.sm.permitTransition("confirmation", "idle"); // When the dialog is dismissed
    this.sm.permitTransition("uploading", "idle");
    window.addEventListener("libdropfile.uploadprogress", event => { this.updateProgressBar(event) })
    this.currentClipsUploading = [];
    this.sm.havingEntered("idle", ()=> {
      // Make sure data used during the previous upload can be GC-ed
      this.currentClipsUploading.length = 0;
    });
  }

  async acceptDroppedFiles(files) {
    if (libdropfile.containsInvalidFileTypes(files)) {
      alert(libdropfile.INVALID_FILE_TYPES_MSG);
      return;
    }

    this.sm.transitionTo("confirmation");
    const filesPerFilename = {};
    files.forEach((f) => filesPerFilename[f.name] = f);

    const groupFilesResponse = await libdropfile.fetchWithProjectPrefix("group-files", "POST", {paths: Object.keys(filesPerFilename)});
    const detectedClips = await groupFilesResponse.json();

    // Partition the dropped files per actual dropped clip, by filename
    detectedClips.map((detectedClip) => {
      detectedClip.orderedFiles = detectedClip.frames.map((frameFilename) => filesPerFilename[frameFilename])
    });

    // "Park" the dropped files on the controller until the modal is either dismissed
    // or confirmed to begin the actual upload
    this.currentClipsUploading =  detectedClips;

    // Inside the dialog target, replicate the template for the clip information as many times as necessary
    this.prepareDialogInputs(detectedClips);
    showModal(this.dialog);
  }

  prepareDialogInputs(detectedClips) {
    // TODO: find another method of rendering multiple shot blocks since this copying seems very hacky
    // and needs specific cleanup
    const oneShotBlock = this.dialog.querySelector('.upload-modal-shot');
    detectedClips.map((detectedClip, i) => {
      const clonedBlock = oneShotBlock.cloneNode(true); // deep clone
      const shotTitleInput = clonedBlock.querySelector('[data-input-destination=title]');
      shotTitleInput.value = detectedClip.pattern;
      shotTitleInput.name = `shots[${i}][title]`;

      const versionTitleInput = clonedBlock.querySelector('.version-input-title');
      versionTitleInput.name = `shots[${i}][version][title]`
      oneShotBlock.insertAdjacentElement('afterend', clonedBlock);
    });
    oneShotBlock.remove();
  }

  async uploadSingleSequence(detectedSequence) {
    // Step 1 - create the new shot, versions, clips etc.
    let title = detectedSequence.pattern;
    let versionTitle = detectedSequence.versionTitle;

    const clipDescriptorResponse = await libdropfile.fetchWithProjectPrefix("prepare-shot", "POST", {
      shot: {title, version: {title: versionTitle}}
    });
    if (!clipDescriptorResponse.ok) {
      throw new Error(`The response for clip creation was not OK: ${clipDescriptorResponse.status}`);
    }
    const clipDescriptor = await clipDescriptorResponse.json()
    console.log(`Uploading new shot ${clipDescriptor.shot.id}`);

    // Step 2 - upload the actual files
    let progressEventsTo = this.element;
    let uploadPromises = libdropfile.createUploadPromises(detectedSequence.orderedFiles, progressEventsTo);
    let blobsArr = await Promise.all(uploadPromises); // wait until the actual upload completes
    let signedBlobIds = blobsArr.map((e) => e.signed_id);
    let signedClipId = clipDescriptor.clip.signed_id_for_attachment;

    // Step 3 - link uploaded blobs to the clip
    let attachResponse = await libdropfile.fetchWithProjectPrefix("attach-source-file-blobs", "PUT", {signed_clip_id: signedClipId, signed_blob_ids: signedBlobIds})
    if (!attachResponse.ok) {
      throw new Error(`The response for blob attachment was not OK: ${clipDescriptorResponse.status}`);
    }
  }

  async performFileUploads() {
    // TODO: validate that the backend would accept shot titles if we need
    // to do any validation, initialize rollback buffer
    this.sm.transitionTo("uploading");

    // Collect shot and version titles from the modal
    const shotTitleInputs = this.dialog.querySelectorAll('[data-input-destination=title]');
    const shotTitlesFromUserInput = Array.from(shotTitleInputs).map((el) => el.value);

    const versionTitleInputs = this.dialog.querySelectorAll('.version-input-title');
    const versionTitlesFromUserInput = Array.from(versionTitleInputs).map((el) => el.value);
    try {
      for (let [i, seq] of this.currentClipsUploading.entries()) {
        await this.uploadSingleSequence({...seq, pattern: shotTitlesFromUserInput[i], versionTitle: versionTitlesFromUserInput[i]});
      }
    } finally {
      this.sm.transitionTo("idle")
    }
  }

  dismiss(evt) {
    this.dropzone.removeEventListener("libdropfile.uploadprogress", this.updateProgressBar)

    // Delete the extra copied shot upload-modal-shot blocks, because else they will reappear again when
    // the user does another upload
    const shotBlocks = evt.target.closest('.uploadModal').querySelectorAll('.upload-modal-shot');
    if (shotBlocks.length > 1) {
      // delete all but the last one (which initially was the first one)
      for (let i = 0; i < shotBlocks.length - 1; i++) {
        shotBlocks[i].remove();
      }
    }

    this.sm.transitionTo("idle");
  }
}
