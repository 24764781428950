import { Controller } from "stimulus";
import { EventListenerManager } from "libraries/libevents";
import Stam from "libraries/stam"
import { throttle } from 'throttle-debounce';

/*
  This Stimulus controller works on the following premise:

  - When attached to an element, the element will after some milliseconds
    receive the "state-mouse-idle" class, to allow it to fade out
  - When the mouse moves around the screen, the element appears again (class gets removed)

You can define how long it will take for the state-mouse-idle class to be added (how long should
the cursor be stationary) in the "disappear-after-millis" data attribute of the element. The
default is 2000 (two seconds)
*/
export default class extends Controller {
  // The only target for the controller is the element itself, so no targets needed.

  connect() {
    this.disappearAfter = parseInt(this.element.dataset.disappearAfterMillis) || 2000;

    this.displayStates = new Stam("visible", "hidden").assume("visible");
    this.displayStates.permitTransition("visible", "hidden", "visible");

    // Since we are using a timeout and events there might be some hammering on the
    // state machine to make the palette visible in rapid succession. For us it is
    // not really that important that "visible"->"visible" transitions are tracked.
    this.displayStates.ignoreRepeatedTransitions = true;
    this.displayStates.debug = true;
    
    // Handle all restyles through the state transitions
    this.displayStates.havingLeft("visible", () => this.element.classList.add('state-mouse-idle'));
    this.displayStates.havingLeft("hidden", () => this.element.classList.remove('state-mouse-idle'));

    // We debounce the mousemove a little because we remove and add timers all the time
    this.eventManager = new EventListenerManager();
    this.eventManager.add(document, 'mousemove', throttle(30, this.mousemoveHandler.bind(this)));

    // Call the mousemove handler deliberately to cause the initial "disappearance". This call is sans-event
    // so the bounding rect check will be skipped
    this.mousemoveHandler();
  }

  mousemoveHandler(evt) {
    // Clear the existing timer which is going to hide the element. Even though
    // we will have some churn on the timers it is easier to unset the timer unconditionally,
    // to make the logic a bit simpler.
    clearTimeout(this.hideTimeoutId);

    // Any mouse move causes the element to appear
    this.displayStates.transitionTo("visible");

    // ...and the timer to be re-armed
    const disappearFn = () => this.displayStates.transitionTo("hidden");
    this.hideTimeoutId = setTimeout(disappearFn, this.disappearAfter)
  }

  disconnect() {
    clearTimeout(this.hideTimeoutId);
    this.eventManager.destroy();
  }
}
