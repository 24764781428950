const BRUSH_BUFFER_ID = '---custom-brush-draw-buffer';
const MINIMUM_DIAMETER_TO_DRAW_BRUSH = 5; // How small the brush has to be before we go crosshair
const DRAW_CROSSHAIR_INSIDE_ABOVE= 12;

const MAXIMUM_ALLOWED_DIAMETER = 32; // After this the cursor image will not be loaded by the browser

const CROSSHAIR_RAY_LEN = 6;
const CROSSHAIR_GAP = 4;
const CROSSHAIR_DIMENSION = (CROSSHAIR_RAY_LEN * 2) + (CROSSHAIR_GAP *2) + 1;

// Class representing a brush cursor
// of a certain diameter
class BrushSprite {
  constructor(diameter, textualSize){
    this.textualSize = textualSize;
    const pad = 0;
    // The stroke drawn on the canvas with this line thickness will be a bit
    // thick, so we bump up the diameter so that the brush is drawn OUTSIDE
    // of the line being drawn. We add 4 so that we have at least a pixel
    // empty on the inside of the circle, between the line being drawn
    // and the circle of the brush
    if (diameter < 0.5) { diameter = 0.5; } // The absolute MINUMOM
    this.diameter = diameter + pad; // Leave some space so that we pai
  }

  // Returns the image data wrapped in "url()"
  getBackgroundDeclaration() {
    const cursorDataUri = this.getBrushImageData();
    return `url(${cursorDataUri}), crosshair`;
  }

  // Returns the Base64-encoded bitmap cursor
  getBrushImageData() {
    console.debug(`Generating a brush bitmap with D ${this.diameter}`);

    const c = getBuf();
    const radius = this.diameter / 2;

    const canvasDimension = this.getBrushCanvasSize();

    c.width = canvasDimension;
    c.height = canvasDimension;

    const centerOffset = Math.round(canvasDimension / 2);

    // Wipe!
    const ctx = c.getContext("2d");
    ctx.clearRect(0, 0, c.width, c.height);

    // Move the context to the center of the canvas
    ctx.translate(centerOffset, centerOffset);

    // Draw the shadow...
    ctx.save();
    ctx.translate(0.5, 0.5);
    this.drawBrush(ctx, radius, 'black');

    // And the white brush
    ctx.restore();
    this.drawBrush(ctx, radius, 'white');

    // Return the base64-encoded image
    // which can be stuffed into CSS right away
    return c.toDataURL();
  }

  /*
    Returns a data URL for a "black dot"
    image that can be used in things like toolbars
  */
  getBlackDot() {
    const c = getBuf();

    const radius = this.diameter / 2;
    console.debug(`Generating solid point with R ${radius}`);

    const canvasDimension = this.getBrushCanvasSize();

    c.width = canvasDimension;
    c.height = canvasDimension;
    const centerOffset = canvasDimension / 2;

    const ctx = c.getContext("2d");

    // Wipe!
    ctx.clearRect(0, 0, c.width, c.height);

    // Move the context to the center of the canvas
    ctx.translate(centerOffset, centerOffset);
    this.drawBrush(ctx, radius);

    // Draw the baby
    ctx.fillStyle = 'black';
    ctx.beginPath();
    ctx.arc(0, 0, radius, 0, 2 * Math.PI);
    ctx.fill();

    // Return the base64-encoded image
    // which can be stuffed into CSS right away
    return c.toDataURL();
  }

  // Draw a brush of specified radius into the passed Canvas context,
  // centering the brush bitmap on the context 0,0 coordinate
  drawBrush(ctx, radius, color){
    ctx.strokeStyle = color || 'black';
    ctx.fillStyle = color || 'black';
    ctx.lineWidth = 1; // thinner than the shadow

    ctx.beginPath();
    ctx.arc(0, 0, radius, 0, 2 * Math.PI);
    ctx.stroke();

    // Draw a tiny cross if the brush is big
    if (this.diameter > DRAW_CROSSHAIR_INSIDE_ABOVE) {
      ctx.translate(-.5,-.5);
      const crossR = radius / 2;
      ctx.fillRect(-crossR, 0, (crossR + 1 + crossR), 1);
      ctx.fillRect(0, -crossR, 1, (crossR + 1 + crossR));
      ctx.translate(.5,.5);
    }

    // Put in the number
    if (this.textualSize) {
      console.debug('Rendering brush size string');
      ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset context xform
      ctx.font = "12px monospace";
      ctx.textBaseline = "bottom";
      return ctx.fillText(Math.round(this.textualSize).toString(), 22, 32);
    }
  }

  // Returns the cursor CSS declaration
  // with the encoded cursor image inline
  getCursorDeclaration() {
    // We need to specify auto as the last value
    // so that it displays, and we need to center it on half diameter
    const cursorCenter = Math.round(this.getBrushCanvasSize() / 2);
    const cursorDataUri = this.getBrushImageData();
    return `url(${cursorDataUri}) ${cursorCenter} ${cursorCenter}, auto`;
  }

  // Get the optimum size of the buffer (one dimension)
  // that will fit a square brush bitmap
  getBrushCanvasSize() {
    return (Math.ceil(this.diameter) * 2) + 2;
  }
}

class CrosshairSprite extends BrushSprite {

  getBrushCanvasSize() {
    return CROSSHAIR_DIMENSION;
  }

  drawCross(ctx){
    const r = CROSSHAIR_RAY_LEN; // Crosshair ray length
    const gap = CROSSHAIR_GAP;
    const t = 1;

    // Draw middle point (1px square)
    ctx.fillRect(0, 0, t, t);
    // Top
    ctx.fillRect(0, - gap - r, t, r);
    // Right
    ctx.fillRect(t + gap, 0, r, t);
    // Bottom
    ctx.fillRect(0, gap + t, t, r);
    // Left
    ctx.fillRect(-gap - r, 0, r, t);
  }

  // Draw a crosshair for when the brush will be too small to display
  drawBrush(ctx){
    ctx.save();

    ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset context xform

    // Move to top left corner of the middle dot of the crosshair
    const foff = CROSSHAIR_RAY_LEN + CROSSHAIR_GAP;
    ctx.translate(foff, foff);

    // Make us a shadow
    ctx.fillStyle = 'black';
    ctx.save();
    ctx.translate(1, 1);
    this.drawCross(ctx);
    ctx.restore();

    // White  cursor
    ctx.fillStyle = 'white';
    this.drawCross(ctx);
    ctx.restore();
  }
}

/*
  We want to reuse the same hidden Canvas for all brushes,
  because if we allocate a new one every time we refresh
  the brush this might cause DOM trashing. So we stash
  a hidden canvas in the document and draw into it instead.
*/
var getBuf = function() {
  let c = document.getElementById(BRUSH_BUFFER_ID);
  if (!c) {
    c = document.body.appendChild(document.createElement("canvas"));
    c.style.display = "none";
    c.id = BRUSH_BUFFER_ID;
  }
  return c;
};


/*
  All we do is generate a PNG from Canvas dynamically, and apply it as
  the cursor image, pretty much as prescribed in this pen
  http://codepen.io/netsi1964/full/DsAhE
*/
const setBrushCursor = function(el, renderedDiameter, displayDiameter) {
  console.debug(`Applying brush cursor D:${renderedDiameter} with caption of ${displayDiameter}`);

  // Pick a sprite from the cache. Most brushes
  // will be cached after repeated use
  const sprite = (() => {
    if (renderedDiameter < MINIMUM_DIAMETER_TO_DRAW_BRUSH) {
    console.debug("Generating a crosshair sprite");
    return new CrosshairSprite(1);
  } else if (renderedDiameter < MAXIMUM_ALLOWED_DIAMETER) {
    console.debug("Generating a circular brush");
    return new BrushSprite(renderedDiameter);
  } else { // HUGE brush
    console.debug("Generating a circular brush with text");
    return new BrushSprite(MAXIMUM_ALLOWED_DIAMETER, displayDiameter);
  }
  })();

  // Assign the cursor
  el.style.cursor = sprite.getCursorDeclaration();
};

// For canvas elements, update brush cursors for Canvas elements that have a cursor assigned,
// honing the CSS-induced scaling factor.
// TODO: check on Retina
function updateBrushesToScale() {
  for (let el of document.querySelectorAll('canvas[brush-cursor-diameter]')) {
    if (el.width) {
      // Compute all the transforms and offsets
      const rect = el.getBoundingClientRect();
      const scale = rect.width / el.width;
      console.debug(`Brush: adjusting scale factor ${scale}`);
      // TODO: handle scaling for the number that we are going to be
      // displaying next to the brush
      const d = parseInt(el.getAttribute('brush-cursor-diameter'));
      setBrushCursor(el, d * scale, d);
    }
  }
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, waitMillis, immediate) {
  let timeout;
  return function() {
    let context = this, args = arguments;
    let later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, waitMillis);
    if (callNow) func.apply(context, args);
  };
}

// Install a global resize handler. That handler will do a data-attribute
// select by itself, so it won't be holding any DOM references
const debouncedUpdate = debounce(updateBrushesToScale, 500);
window.addEventListener('resize', debouncedUpdate);
window.addEventListener('beforeunload', () => window.removeEventListener('resize', debouncedUpdate));
window.addEventListener("turbolinks:before-render", () => window.removeEventListener('resize', debouncedUpdate))

// Export the module
const Brush = {
  // Returns the data URI for the brush image in the interface
  getBlackDot(diameter){
    console.groupCollapsed(`Brush: getting a brush dot bitmap for D:${diameter}`);
    const dotUri = new BrushSprite(diameter).getBlackDot();
    console.groupEnd();
    return dotUri;
  },

  // Apply the needed cursor CSS to the passed DOM element
  applyBrushCursor(el, diameter) {
    // console.groupCollapsed(`Brush: applying cursor of D:${diameter}`);
    el.setAttribute('brush-cursor-diameter', diameter);
    if (el.nodeName === 'CANVAS') {
      console.debug("Brush assigned to a canvas element, so setting it with respect to CSS scale");
      updateBrushesToScale();
    } else {
      setBrushCursor(el, diameter);
    }
    // console.groupEnd();
  },

  refresh() {
    updateBrushesToScale();
  },

  remove(el) {
    el.removeAttribute('brush-cursor-diameter');
    el.style.cursor = null; // Remove local styling
  }
};

export default Brush
