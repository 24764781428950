import { pairwiseEach, pairwiseMap, eachCons, eachSeq, sum } from "./utility_functions";

const DEFAULT_STEPS_PER_SEGMENT = 4;
/*
A number of 1D interpolation functions. We use 1D interpolation and a fixed number of
interpolation steps (T subdivisions) because we can then apply the same interpolator
to both X, Y and thickness - and have it output the same number of values, which then are
easy to combine. Had we chosen for more uniform interpolation steps, we would have had to
first do an approximation of some kind to determine the T increment at each point, and
only then we would be able to interpolate. Since our prime focus is performance _during_
drawing, we benefit heavily from using small, "dumb" units of computation for now.
*/

class InterpLinear {
  interpolate(values, _stepsPerSegment) {
    return values;
  }
}

class InterpCubic {
  fitSplineTerms(values, i) {
    if (values.length < 4) {
      throw new Error(`To fit spline along points at least 4 points are necessary, but only got ${points.length}`)
    }
    return [
      (-values[i - 1] + (3 * (values[i] - values[i + 1])) + values[i + 2]) / 6.0,
      ((values[i - 1] - (2 * values[i])) + values[i + 1]) / 2.0,
      (values[i + 1] - values[i - 1]) / 2.0,
      (values[i - 1] + (4 * values[i]) + values[i + 1]) / 6.0,
    ];
  }

  interpolate(values, stepsPerSegment) {
    // Generate virtual datapoints extrapolated
    // before the first one and after the last one
    const pre = values[1] - values[0];
    const post = values[values.length - 1] - values[values.length - 2];
    const inputValues = [].concat(values[0] - pre, values, values[values.length - 1] + post);
    const interp = [];
    for (let i = 1; i < (inputValues.length - 2); i++) {
      const steps = stepsPerSegment[i] || 8;
      const [a3, a2, a1, a0] = this.fitSplineTerms(inputValues, i);
      const compute = (t) => (((((a3 * t) + a2) * t) + a1) * t) + a0;
      for (let j = 0; j <= steps; j++) {
        const mu = j / steps;
        interp.push(compute(mu));
      }
    }
    return interp;
  }
}

class InterpHermite {
  /*
     Tension: 1 is high, 0 normal, -1 is low
     Bias: 0 is even,
           positive is towards first segment,
           negative towards the other
  */
  constructor(tension=0, bias=0) {
    this.tension = tension;
    this.bias = bias;
  }

  hermiteAt(y0, y1, y2, y3, mu) {
    const {bias, tension} = this;
    let m0,m1,mu2,mu3;
    let a0,a1,a2,a3;

    mu2 = mu * mu;
    mu3 = mu2 * mu;
    m0  = (y1-y0)*(1+bias)*(1-tension)/2;
    m0 += (y2-y1)*(1-bias)*(1-tension)/2;
    m1  = (y2-y1)*(1+bias)*(1-tension)/2;
    m1 += (y3-y2)*(1-bias)*(1-tension)/2;
    a0 =  2*mu3 - 3*mu2 + 1;
    a1 =    mu3 - 2*mu2 + mu;
    a2 =    mu3 -   mu2;
    a3 = -2*mu3 + 3*mu2;

    return (a0*y1+a1*m0+a2*m1+a3*y2);
  }

  interpolate(inputValues, stepsPerSegment) {
    const pre = inputValues[1] - inputValues[0];
    const post = inputValues[inputValues.length - 1] - inputValues[inputValues.length - 2];
    const values = [].concat(inputValues[0], inputValues, inputValues[inputValues.length - 1]);

    const interp = [];
    for (let i = 1; i <= values.length - 1; i++) {
      let steps = stepsPerSegment[i - 1] || 5;
      for (let j = 0; j < steps; j++) {
        let t = j / steps;
        let v = this.hermiteAt(values[i - 1], values[i], values[i + 1], values[i + 2], t);
        interp.push(v);
      }
    }
    return interp;
  }
}

/*
Quadratic Bezier curves are very neat. They are pulled over 3 points - two ends and one control
point which the curve does not touch. We can use quadratic beziers if we use the midpoints
of our input segments (just between the recorded points!) as endpoints, and the registered input
points as the CV (control vertex). This way we will build up our curve out of a number of segments
that connect smoothly in the middle of our various segments. The advantage of this approach is that
we do not need to play with derivatives so that the segments connect smoothly - we can just evaluate
those segments one by one, directly as a quadratic blend. This gives a smoother curve than Hermite
with the disadvantage that the curve only ever goes through the end points, but not through any other points.

A more advanced way to do it would be to determine the angle at the start/end of the segment, and if
the angle is sharper move the bisection point towards that angle (use weighted segmentation). 
We can look at it later though.
*/
class InterpQuadraticBezier {
  interpolateSegment(x0, xcv, x1, nSteps) {
    const r = [];
    const eq = (t) => (1 - t) * (1 - t) * x0 + 2 * (1 - t) * t * xcv + t * t * x1;
    for (let j = 0; j <= nSteps; j++) {
      let t = j / nSteps;
      r.push(eq(t));
    }
    return r;
  }

  interpolate(valuesAt, stepsPerSegment) {
    if (valuesAt.length === 3) {
      return this.interpolateSegment(...valuesAt, sum(stepsPerSegment));
    }

    const vv = [];
    const r = [];

    // and then inject bisection points into the sequence of values.
    pairwiseMap(valuesAt, (a, b) => {
      vv.push((a+b)/2);
      vv.push(b);
    });

    let segmentIndex = 0;
    r.push(valuesAt[0]); // Start pt
    eachSeq(vv, 3, (x0, x1, x2) => {
      segmentIndex++;
      const interpolatedValues = this.interpolateSegment(x0, x1, x2, stepsPerSegment[segmentIndex] || DEFAULT_STEPS_PER_SEGMENT);
      r.push(...interpolatedValues);
    });
    r.push(valuesAt[valuesAt.length - 1]); // End pt
    return r;
  }
}

export {InterpLinear, InterpCubic, InterpHermite, InterpQuadraticBezier};