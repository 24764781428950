import { InterpQuadraticBezier, InterpHermite, InterpLinear, InterpCubic } from "./libpaint/interpolators";
import { pairwiseEach, pairwiseMap, eachCons, eachSeq, sum, bisect, unzip, reals, positive } from "./libpaint/utility_functions";
import { BoundingRect, unionRects } from "./libpaint/bounding_rect";

// For browsers having no pointer events degrade to mouse events. The handling
// code then also assumes fixed pressure of 0.5, which is also the default
// for pointer events with mouse
const EVT_DOWN = ('PointerEvent' in window) ? "pointerdown" : "mousedown";
const EVT_UP = ('PointerEvent' in window) ? "pointerup" : "mouseup";
const EVT_MOVE = ('PointerEvent' in window) ? "pointermove" : "mousemove";
const DEFAULT_PRESSURE = 0.5;
const MAX_POINTS_PER_STROKE = 32;
const MINIMUM_BRUSH_SIZE = 0.1;

// https://github.com/ai/nanoid/blob/master/index.js
function nanoid(size) {
  var url = '_~varfunctio0125634789bdegjhklmpqswxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  size = size || 21;
  var id = '';
  while (0 < size--) {
    id += url[Math.random() * 64 | 0];
  }
  return id;
}

function eventToPoint(pointerEvent, canvas){
  // Compute all the transforms and offsets
  const rect = canvas.getBoundingClientRect();

  // It's a good idea to work well even if the canvas is squashed
  // or stretched non-proportionally.
  const xscale = rect.width / canvas.width;
  const yscale = rect.height / canvas.height;

  // We use clientX instead of pageX because
  // pageX changes when the document is scrolled down from the 0 scroll.
  const x = (pointerEvent.clientX - rect.left) / xscale;
  const y = (pointerEvent.clientY - rect.top) / yscale;
  const ep = (typeof(pointerEvent.pressure) === "number") ? pointerEvent.pressure : DEFAULT_PRESSURE;
  const p = ep * 2; // Make the default pressure (0.5) register as a full pressure multiplier
  return {x, y, p};
}

function renderOp(op, ctx, pxWidth, pxHeight) {
  try {
    ctx.save();
    ctx.globalCompositeOperation = op.comp;
    if (op.points.length > 1) {
      return RenderStrokeOp(op, ctx);
    } else if (op.points.length === 1) {
      return RenderDotOp(op, ctx);
    }
  } finally {
    ctx.restore();
  }
}

function RenderDot(ctx, x, y, r) {
  if (!reals(x, y, r)) return;
  if (!positive(r)) return;
  ctx.beginPath();
  ctx.arc(x, y, r, 0, Math.PI * 2);
  ctx.closePath();
}

function RenderDotOp(op, ctx) {
  const {x, y, p} = op.points[0];
  const {brushSize, color} = op;
  ctx.fillStyle = color;
  RenderDot(ctx, x, y, brushSize * p / 2);
  ctx.fill();

  const bbox = new BoundingRect();
  bbox.extend(x, y, brushSize * p / 2);

  return bbox.toObject();
}

const INTERPOLATORS_PER_SPLINE_TYPE = {
  "hermite": new InterpHermite(),
  "cubic": new InterpCubic(),
  "linear": new InterpLinear(),
  "quadraticBezier": new InterpQuadraticBezier(),
}

function RenderStrokeOp(op, ctx) {
  const {splineType, points, brushSize, color} = op;
  ctx.fillStyle = color;

  const [xs, ys, ws] = unzip(op.points, ({x, y, p}) => [x, y, p * brushSize]);
  // If the cursor traveled less between points A and B we can reduce the number
  // of interpolated straight segments between them, which stays sane on compute.
  // The longer the distance between two registered input points, the more interpolation
  // steps we need to compute between those points.
  const segmentPixelLength = 3;
  const pxDistances = pairwiseMap(op.points, (a, b) => Math.hypot(b.x - a.x, b.y - a.y));
  const stepsPerSegment = pxDistances.map((dist) => Math.ceil(dist / segmentPixelLength));

  // If we only have 2 points, revert to linear (cubic won't produce points, hermite will produce linear in fact)
  const lineInterpolator = xs.length < 3 ? INTERPOLATORS_PER_SPLINE_TYPE["linear"] : INTERPOLATORS_PER_SPLINE_TYPE[splineType];

  const xi = lineInterpolator.interpolate(xs, stepsPerSegment);
  const yi = lineInterpolator.interpolate(ys, stepsPerSegment);
  // Use the _exact same_ interpolator for the brush thickness as well. Linear would be faster and more predictable BUT
  // we need out interpolators to output exactly the same number of data points, along the same T values. We do need
  // to lowpass-filter it's output so that it does not create brushes of negative size for us. What would that be -
  // an antimatter brush?
  const wi = lineInterpolator.interpolate(ws, stepsPerSegment).map((v) => Math.max(0, v));

  // Compute the bounding box for the stroke using all our segment joints
  const bbox = new BoundingRect();
  for (let i = 0; i < xi.length; i++) {
    bbox.extend(xi[i], yi[i], wi[i] / 2);
  }
  for (let i = 1; i < xi.length; i++) {
    RenderChainlink(ctx, xi[i-1], yi[i-1], xi[i], yi[i], wi[i-1], wi[i]);
  }

  return bbox.toObject();
}

function RenderRoundedLine(ctx, x1, y1, x2, y2, w) {
  ctx.strokeStyle = ctx.fillStyle;
  ctx.beginPath();
  ctx.lineCap = "round";
  ctx.lineWidth = w;
  ctx.moveTo(x1, y1);
  ctx.lineTo(x2, y2);
  ctx.stroke();
}
// The initial version was lifted from
// https://gamealchemist.wordpress.com/2013/08/28/variable-width-lines-in-html5-canvas/
// That version has a flaw though - it would draw half-circles and join them with lines, creating
// "kinks" which are ever more noticeable with short chainlinks and substantial difference in radii.
// What we need though is a triangle which is rounded off on two ends, which then gets mirrored
// along the line segment that is the "bone" of the chainlink segment.
function RenderChainlink(ctx, x1, y1, x2, y2, w1, w2) {
  if (!reals(x1, y1, x2, y2, w1, w2)) return;
  if (!positive(w1, w2)) return;

  const avgWidth = (w1 + w2) / 2;

  // If the line doesn't change thickness be cheap and render a rounded line stroke
  if (Math.abs(w1 - w2) < 0.5) {
    return RenderRoundedLine(ctx, x1, y1, x2, y2, avgWidth);
  }
  // The algorithm is made for the case when r2 > r1.
  if (w1 > w2) {
    return RenderChainlink(ctx, x2, y2, x1, y1, w2, w1);
  }

  // We need to get the vector of our hypotenuse (the "leg" of the chain link)
  const [dx, dy] = [x2-x1, y2-y1];
  const hypot = Math.hypot(dx, dy);

  // If the segment is extremely short - degrade into a single point as well
  if (hypot < Number.EPSILON) {
    RenderDot(ctx, x2, y2, avgWidth/2);
    return;
  }

  const [r1, r2] = [w1/2, w2/2];
  const a = Math.abs(r1 - r2); // Delta between R1 and R2 (the side of the right triangle)
  let connectionAngle = Math.asin(a / hypot);

  // There is also a second degradation case where both the "chain link" segment and the smaller joiner arc
  // end up inside of the bigger joiner arc - in that case the shape becomes a circle. This can be detected
  // by checking for NaN in the asin return value
  if (isNaN(connectionAngle)) {
    RenderDot(ctx, x2, y2, avgWidth/2);
    return;
  }

  // Now we need the rotation of our chainlink to draw arcs at correct starting angle
  const vecAngle = Math.atan2(dx, dy);
  // Canvas pulls lines between arcs by itself, no need to do lineTo - it is enough to start
  // the arc at the right point
  ctx.beginPath();
  // r1 is the smaller one. If it is infinitesimally small, just draw a pointy point.
  if (r1 < Number.EPSILON) {
    ctx.moveTo(x1, y1);
  } else { // draw a rounding
    ctx.arc(x1, y1, r1, Math.PI + connectionAngle - vecAngle, -connectionAngle - vecAngle);
  }
  // The bigger end is always round
  ctx.arc(x2, y2, r2, -connectionAngle - vecAngle, Math.PI + connectionAngle - vecAngle);
  ctx.closePath();
  ctx.fill();
}

function pointerIdFromEvent(evt) {
  if (typeof(evt.pointerId) === "number") {
    return evt.pointerId;
  } else {
    return 0;
  }
}

class PaintSurface {
  constructor(destinationCanvasElement, opstack = []) {
    this.opstack = Array.from(opstack);
    this.canvas = destinationCanvasElement;
    this.points = []; // Points in the stroke that is being input right now
    this.nextOpAttributes = {  // Op attrs for the stroke that is being input right now
      color: "#FFFFFF",
      brushSize: 15,
      comp: "source-over",
      splineType: "quadraticBezier",
    };
    this.active = false; // Tells whether input is currently being accepted
    this.drawingPointerId = 0; // Tells which cursor is doing paint at the moment
    this.undoStack = [];
    this.bufferCtx = this.canvas.getContext('2d');
  }

  addImage(image) {
    this.bufferCtx.drawImage(image, 0, 0, this.canvas.width, this.canvas.height);
  }

  clear() {
    this.opstack = [];
    this.forceRender();
  }

  clearOnlyCanvas() {
    this.bufferCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  undo() {
    const lastOp = this.opstack.pop();
    if (lastOp) {
      this.undoStack.push(lastOp);
      this.forceRender();
      this.dispatchOpstackChanged();
    }
  }

  redo() {
    const nextOp = this.undoStack.pop();
    if (nextOp) {
      this.opstack.push(nextOp);
      this.render();
      this.dispatchOpstackChanged();
    }
  }

  setupEventListeners() {
    // These need to be prebound. We can't do
    //   addEventListener(evtName, this.handler.bind(this))
    // because after this will not work:
    //   removeEventListener(evtName, this.handler.bind(this))
    // since
    //  this.meth.bind(this) === this.meth.bind(this) => false
    // because yolo JavaScript.
    this.preboundDraw = (evt) => {
      if (pointerIdFromEvent(evt) !== this.drawingPointerId) return; // Skip events for the other pointer
      // Only spend time re-rendering the opstack if we did add a point indeed
      if (this.pushPoint(evt)) {
        this.render();
      }
    };

    this.preboundStartInput = (evt) => {
      if (evt.button) return; // Prevent the stroke from starting on a context menu
      if (this.active) return; // Prevent double stroke start for whatever reason

      // Extract the pointer ID for later
      this.drawingPointerId = pointerIdFromEvent(evt);
      this.active = true;
      this.currentOpAttributes = Object.assign({id: nanoid()}, this.nextOpAttributes);

      document.addEventListener(EVT_MOVE, this.preboundDraw);
      this.preboundDraw(evt);
    };

    this.preboundEndStroke = (evt) => {
      if (!this.active) return; // Skip events which are not ours to handle
      if (pointerIdFromEvent(evt) !== this.drawingPointerId) return; // Skip events for the other pointer

      // Remove the listener
      document.removeEventListener(EVT_MOVE, this.preboundDraw);

      // Copy the current op and the points we accumulated into the opstack
      const strokeOp = Object.assign({}, this.currentOpAttributes, {points: this.points.slice(0)});
      this.opstack.push(strokeOp);
      this.points.length = 0;
      this.active = false;
      this.render();
      // Once rendered, combine the last strokes which could have been split during painting back into one single stroke.
      this.spliceStrokes();
      // Clear the undo stack so that we do not create alternate histories
      this.undoStack.length = 0;
      this.dispatchOpstackChanged();
    }
    // Install event listeners on document, to continue drawing
    // when cursor goes outside the canvas
    this.destinationCanvas.addEventListener(EVT_DOWN, this.preboundStartInput);

    // This evt handler needs to be disabled as well when we disableListeners...
    document.addEventListener(EVT_UP, this.preboundEndStroke);
  }

  get mayRedo() {
    return this.undoStack.length > 0;
  }

  get mayUndo() {
    return this.opstack.length > 0;
  }

  pushPoint(pointerEvt) {
    // How far did we have to travel from the previous point for the input
    // to register?
    const PX_INPUT_DELTA = 0.8;
    const maybePrevious = this.points[this.points.length - 1];
    const pt = eventToPoint(pointerEvt, this.destinationCanvas);
    if (!maybePrevious) {
      this.points.push(pt);
      return true;
    }
    // Only add the point if it's sufficiently far from the previous to make a difference
    const distFromPrev = Math.hypot(pt.x - maybePrevious.x, pt.y - maybePrevious.y);
    if (distFromPrev > PX_INPUT_DELTA) {
      this.points.push(pt);
      return true;
    }
  }

  forceRender() {
    const bufferCtx = this.bufferCanvas.getContext('2d');
    bufferCtx.clearRect(0, 0, bufferCtx.canvas.width, bufferCtx.canvas.height);
    this.barrier = -1;
    this.render();
  }

  spliceStrokes() {
    let newOpstack = [];
    for (let op of this.opstack) {
      let last = newOpstack[newOpstack.length - 1];
      if (last && last.id === op.id) {
        console.debug(`Splicing stroke ${op.id}`);
        // Remove the connecting bisector point
        last.points.pop();
        // Make a combined stroke of the two
        last.points = [...last.points, ...op.points];
        const rect = unionRects(last.bbox, op.bbox);
        last.bbox = rect;
      } else {
        newOpstack.push(op);
      }
    }
    this.opstack = newOpstack;
    this.barrier = this.opstack.length - 1;
  }

  render() {
    const bufferCtx = this.bufferCanvas.getContext('2d');
    const currentStrokeCtx = this.currentStrokeCanvas.getContext('2d');
    const destinationCtx = this.destinationCanvas.getContext('2d');
    const pxWidth = this.destinationCanvas.width;
    const pxHeight = this.destinationCanvas.height;

    // Render the opstack into the buffer, and skip ops which are below the barrier value. Ops which
    // are "prior" to the barrier get cached in the buffer canvas between strokes (they are drawn already),
    // and rerendering them every time is prohibitevly expensive the more strokes
    // we get. So we render the operations from the opstack which are below a certain
    // index only, and then only render the ones higher than that barrier
    const unrenderedOps = this.opstack.slice(this.barrier + 1, this.opstack.length);
    for (let op of unrenderedOps) {
      op.bbox = renderOp(op, bufferCtx, pxWidth, pxHeight);
    }
    this.barrier = this.opstack.length - 1;

    // Clear the stroke buffer, and preheat it with the buffer which now contains the entire
    // opstack, rendered. This is the operation that will be repeated on every render() call.
    currentStrokeCtx.clearRect(0, 0, pxWidth, pxHeight);
    currentStrokeCtx.drawImage(this.bufferCanvas, 0, 0, pxWidth, pxHeight);

    // Render the current ("in-flight") stroke into the same buffer
    const {points, currentOpAttributes} = this;
    if (points.length > 0) {
      renderOp({...currentOpAttributes, points}, currentStrokeCtx, pxWidth, pxHeight);
    }
    // Cheat like crazy. If the in-flight stroke has become _very_ long
    // move some of the points into a separate op on the stack already so
    // that it gets cached on the subsequent render().
    if (points.length > MAX_POINTS_PER_STROKE) {
      console.debug("Long stroke. Splitting off some points into a separate op");
      // Take the first N points and remove them from the points array
      const firstN = points.splice(0, MAX_POINTS_PER_STROKE - 4);
      // ...and join the previous segment to this one using a point located between the last
      // point of the op we just cut off and the first point of the current working stroke.
      const connectorPoint = bisect(firstN[firstN.length - 1], points[0]);
      firstN.push(connectorPoint);
      points.unshift(connectorPoint);
      this.opstack.push({...currentOpAttributes, points: firstN});
    }
    // Time to lay down the sandwich.
    // 1. Clear the destination canvas from the previous drawing
    destinationCtx.clearRect(0, 0, pxWidth, pxHeight);
    // 2. Copy the stashed canvas into destination
    destinationCtx.drawImage(this.stashCanvas, 0, 0, pxWidth, pxHeight);
    // 3. Comp our strokes (buffer + current stroke) into destination
    destinationCtx.drawImage(this.currentStrokeCanvas, 0, 0, pxWidth, pxHeight);
  }

  teardownEventListeners() {
    this.destinationCanvas.removeEventListener(EVT_DOWN, this.preboundStartInput);
    document.removeEventListener(EVT_MOVE, this.preboundDraw);
    document.removeEventListener(EVT_UP, this.preboundEndStroke);
  }

  destroy() {
    this.teardownEventListeners();
    // Allow all the intermediate buffers to go out of scope
    this.destinationCanvas = null;
    this.bufferCanvas = null;
    this.stashCanvas = null;
    this.currentStrokeCanvas = null;
  }

  get brushSize() {
    return this.nextOpAttributes.brushSize;
  }

  set brushSize(sizePx) {
    this.nextOpAttributes.brushSize = Math.max(MINIMUM_BRUSH_SIZE, parseFloat(sizePx));
  }

  get color() {
    return this.nextOpAttributes.color;
  }

  set color(cssColorValue) {
    this.nextOpAttributes.color = cssColorValue;
  }

  get canvas() {
    return this.destinationCanvas;
  }

  set canvas(domNode) {
    this.destinationCanvas = domNode;

    // Allow old canvas objects to go out of scope
    this.bufferCanvas = null;
    this.stashCanvas = null;
    this.currentStrokeCanvas = null;

    // It would be super nice if https://developer.mozilla.org/en-US/docs/Web/API/OffscreenCanvas
    // was already supported places, and supported 2D contexts. It doesn't tho, so we will cheat.
    // For this technique we need to have an "offscreen" canvas that we render all our strokes to.
    // This buffer contains strokes on the "opstack" - strokes which have been committed to the buffer.
    // Re-rendering all the strokes every time (and we have to re-render during painting since
    // the stroke shape changes as more points get added) is very expensive computationally. And
    // a pixel buffer is the most "economical" way to represent committed strokes.
    // So we need to create THREE (yes, 3) additional buffers.
    //
    // * buffer - For the strokes on the opstack (loaded externally or already painted), it's contents stays the same
    //    between painting strokes unless you need to undo - in which case we can re-render it from the opstack.
    //    It's contents are modified and retained as new paint strokes get applied.
    // * stash - The contents of the destination canvas before any strokes are applied to it
    //    It's contents is kept the same and always contains the original image
    // * stroke - The current stroke (whose point count/shape changes and which needs re-rendering from scratch)
    //    It's contents gets cleared and rendered anew every time an input event is registered
    const makeCanvasBuf = () => {
      const c = document.createElement('canvas');
      c.width = this.destinationCanvas.width;
      c.height = this.destinationCanvas.height;
      return c;
    }
    this.bufferCanvas = makeCanvasBuf();
    this.stashCanvas = makeCanvasBuf();
    this.currentStrokeCanvas = makeCanvasBuf(); // This can be made lazy (only needed for/during input)
    this.barrier = -1; // The index of the last item in the "opstack" that has been already rendered into "buffer"

    // Store the original image (without strokes) in the stash buffer
    const stashCtx = this.stashCanvas.getContext('2d');
    stashCtx.drawImage(this.destinationCanvas, 0, 0, this.destinationCanvas.width, this.destinationCanvas.height);
  }

  dispatchOpstackChanged() {
    if (!this.destinationCanvas) return;

    const detail = {
      bufferCanvas: this.bufferCanvas,
      opstack: this.opstack,
      nextOpAttributes: this.nextOpAttributes,
    }
    const changedEvt = new CustomEvent('PaintSurface:opstackchanged', {bubbles: true, detail});
    this.destinationCanvas.dispatchEvent(changedEvt);
  }


  get mode() {
    return this.nextOpAttributes.comp === "source-over" ? "paint" : "erase";
  }

  get splineType() {
    return this.nextOpAttributes.splineType;
  }

  set splineType(newType) {
    if (INTERPOLATORS_PER_SPLINE_TYPE[newType]) {
      this.nextOpAttributes.splineType = newType;
    } else {
      throw new Error(`Unknown spline type ${newType} - supported are ${Object.keys(INTERPOLATORS_PER_SPLINE_TYPE)}`);
    }
  }

  set mode(newMode) {
    if (newMode === "paint") {
      this.nextOpAttributes.comp = "source-over"
    } else if(newMode === "erase") {
      this.nextOpAttributes.comp = "destination-out"
    } else {
      throw new Error(`Unknown paint mode ${newMode} - permitted are "paint" and "erase"`);
    }
  }

  opstackAsJSON(pretty = false) {
    const decimalPlaces = 2;
    const floatTruncatingReplacer = (_key, value) => {
      if (typeof value === 'number' && !Number.isInteger(value)) {
        return parseFloat(value.toFixed(decimalPlaces));
      } else {
        return value;
      }
    };
    const indent = pretty ? 2 : 0;
    return JSON.stringify(this.opstack, floatTruncatingReplacer, indent);
  }

  bufferContentsAsBlob(callback, mimeType, qualityArgument) {
    return this.bufferCanvas.toBlob(callback, mimeType, qualityArgument);
  }

  bufferContentsAsDataURL(type, encoderOptions) {
    return this.bufferCanvas.toDataURL(type, encoderOptions);
  }
}

export { PaintSurface }
