import { EventListenerManager } from "libraries/libevents";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "list", "username", "message", "commentErrors", "screen", "commentForm",
                     "opstackOut", "paintContainer", "togglePaintBtn", "formBtns", "cancelBtn",
                     "dynamicList", "dynamicComment", "saveBtn", "drawingImageField", "paintCanvas",
                     "commentFormDrawingIcon" ]

  // resize the message input box when for ex typing
  autoresizeMessageInput(evt) {
    const el = evt.target;
    el.style.cssText = "height:auto;";
    // for box-sizing other than "content-box" use:
    // el.style.cssText = '-moz-box-sizing:content-box';
    el.setAttribute("data-text-height", el.scrollHeight);
    el.style.cssText = "height:" + el.scrollHeight + "px";
    el.scrollIntoView({block: "center"});
  }

  changeCommentInput(evt) {
    if (this.messageTarget.value.trim() === "") {
      this.saveBtnTarget.setAttribute("disabled", true);
    } else {
      this.saveBtnTarget.removeAttribute("disabled");
    }
  }

  isButtonInTextareaClicked(evt) {
    // Firefox has crappy supprt for related target so I use explicitOriginalTarget which
    // can give back the text node of the button.
    const relatedTarget = evt.relatedTarget;
    const expTarget = evt.explicitOriginalTarget;

    if (relatedTarget) {
      return relatedTarget === this.cancelBtnTarget ||
             relatedTarget === this.saveBtnTarget;
    } else if (expTarget) {
      return expTarget === this.cancelBtnTarget ||
             expTarget === this.saveBtnTarget ||
             expTarget.nodeName === "#text";
    }
  }

  blurMessageInput(evt) {
    const el = evt.target;
    this.eventManager.add(window, "mouseup", () => {

      if (this.isButtonInTextareaClicked(evt)) {
        this.commentFormTarget.style = {};
      } else {
        this.formBtnsTarget.classList.add("is-hidden");
        el.classList.remove("comment-textexpand");
        el.style.cssText = '';
      }
      if (this.messageTarget.value == "") {
        // input value is empty and color border can be removed
        this.commentFormTarget.classList.remove("focused");
      }
    }, {once: true})
  }

  focusMessageInput(evt) {
    const el = evt.target;
    this.formBtnsTarget.classList.remove("is-hidden")
    this.messageTarget.classList.add("comment-textexpand");
    el.style.cssText = "height: " + el.getAttribute("data-text-height") + "px";
    this.commentFormTarget.classList.add("focused");
    el.scrollIntoView({block: "center"});
    if (el.value === null || el.value.trim() === "") {
      this.saveBtnTarget.setAttribute("disabled", true);
    } else {
      this.saveBtnTarget.removeAttribute("disabled");
    }
  }

  addCommentForm(evt) {
    event.preventDefault();
    evt.target.classList.add("is-invisible");
    this.paintContainerTarget.classList.remove("is-invisible");
    this.togglePaintBtnTarget.classList = ["btn-showing-paint"];
  }

  jumpToComment(evt) {
    evt.preventDefault();
    let block = evt.target.parentNode;
    let frame = block.getAttribute("data-frame");
    let comment = this.listTarget.querySelector(`div[data-frame="${frame}"]`)
    comment.classList.add("comment__highlight");
    setTimeout(() => {comment.classList.remove("comment__highlight")}, 2000);
  }

  cancelCommentForm(evt) {
    evt.preventDefault();
    this.messageTarget.value = "";
    this.messageTarget.setAttribute("data-text-height", "");
    this.messageTarget.style.cssText = '';
    this.messageTarget.classList.remove("comment-textexpand");
    this.formBtnsTarget.classList.add("is-hidden");
    this.commentFormDrawingIconTarget.classList.add("is-hidden");
    this.commentFormTarget.classList.remove("focused");

    if (this.opstackOutTarget.value.length > 0) {
      this.opstackOutTarget.value = "";
    }
  }

  toggleCommentForm(evt) {
    if (!document.fullscreenElement) return;
    const form = this.commentFormTarget;
    if (form.classList.contains("is-invisible")) {
      form.classList.remove("is-invisible");
    } else {
      form.classList.add("is-invisible");
    }
  }

  addCommentToView(htmlText) {
    const parser = new DOMParser();
    const commentsDoc = parser.parseFromString(htmlText, 'text/html');
    const frame = commentsDoc.body.querySelector(".comment-block").dataset.frame;
    this.listTarget.insertBefore(commentsDoc.body.querySelector(".comment-block"), this.listTarget.firstChild);
    this.dynamicListTarget.insertBefore(commentsDoc.body.querySelector(".dyn-comment-block"), this.dynamicListTarget.firstChild);
    this.dynamicListTarget.firstChild.classList.remove("is-hidden");

    this.messageTarget.value = "";
    this.messageTarget.classList.remove("comment-textexpand");
    this.formBtnsTarget.classList.add("is-hidden");
    this.commentFormTarget.classList.remove("focused");

    this.messageTarget.style = {};
    if (this.opstackOutTarget.value.length > 0) {
      this.opstackOutTarget.value = "";
    }
    const addedEvent = new CustomEvent('comments.commentAdded', {bubbles: true, detail: parseInt(frame, 10)});
    this.element.dispatchEvent(addedEvent);

    // Reload the comments track
    const reflowEvent = new CustomEvent("reflow:selector", {bubbles: true, detail: '.commentTrack'});
    this.element.dispatchEvent(reflowEvent);
  }


  submitWithFormData(formData) {
    if (this.messageTarget.value !== "" || this.opstackOutTarget.value.length > 0) {
      fetch(this.commentFormTarget.action, {
        method: "post",
        body: formData
      }).then(response => {
        if(response.status === 200) {
          return response.text();
        }
      }).then(htmlText => this.addCommentToView(htmlText))
    }
  }

  canvasToBlob(formData) {
    this.paintCanvasTarget.toBlob((blob) => {
      formData.append("comment[drawing_image]", blob, "filename.png");
      this.submitWithFormData(formData);
     })
  }

  submitComment(evt) {
    evt.preventDefault();
    let formData = new FormData(this.commentFormTarget);
    if (this.opstackOutTarget.value.length > 0) {
      this.canvasToBlob(formData);
    } else {
      this.submitWithFormData(formData);
    }
  }

  updateDynamicList(evt) {
    if (this.playing) {
      this.dynamicCommentTargets.forEach(c => c.classList.add("is-hidden"));
    } else {
      this.dynamicCommentTargets.forEach(c => {
        if (c.dataset.frame == evt.detail) {
          c.classList.remove("is-hidden");
        } else {
          c.classList.add("is-hidden");
        }
      })
    };
  }

  connect() {
    this.playing = false;
    this.eventManager = new EventListenerManager();
    this.eventManager.add(window, 'playerkeys.toggleCommentForm', this.toggleCommentForm.bind(this));
    this.eventManager.add(this.messageTarget, 'input', this.autoresizeMessageInput.bind(this));
    this.eventManager.add(this.messageTarget, 'focusout', this.blurMessageInput.bind(this));
    this.eventManager.add(this.messageTarget, 'focusin', this.focusMessageInput.bind(this));
    this.eventManager.add(window, 'atframe', this.updateDynamicList.bind(this));
    this.eventManager.add(window, 'libausync.play', () => { this.playing = true });
    this.eventManager.add(window, 'libausync.pause', () => { this.playing = false });

  }

  disconnect() {
    this.eventManager.destroy();
  }
}
