import { Controller } from "stimulus";
import { EventListenerManager } from "libraries/libevents";

// Controller for elements which cannot be links (due to invalid nesting)
// but still have to be clickable as if they were links

export default class PopoverController extends Controller {
  connect() {
    const evts = new EventListenerManager();
    const clickHandler = (evt) => window.location = this.element.dataset.href;
    evts.add(this.element, 'click', clickHandler);
    this.disconnect = () => evts.destroy();
  }
}
