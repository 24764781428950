// Helper library for the modal component
//
// Used in a modal (de)activation controller to show or hide the modal that wraps around it

export function showModal(element) {
  const el = element || this.element;
  const showModalEvent = new CustomEvent("modal.show", {bubbles: true});
  el.dispatchEvent(showModalEvent);
}

export function hideModal(element) {
  const el = element || this.element;
  const hideModalEvent = new CustomEvent("modal.hide", {bubbles: true});
  el.dispatchEvent(hideModalEvent);
}
