import { Controller } from "stimulus"
import * as libdropzone from "libraries/libdropzone";

// Base class for a dropzone accepting files.
// Make sure to call super.connect() and super.disconnect() in your descendant.
export default class FileDropzoneController extends Controller {
  connect() {
    this.dem = libdropzone.createEventManager(this.element);
    this.dem.add(this.element, "libdropzone:filesdrop", this.acceptDroppedFilesWithDetail.bind(this));
  }

  updateProgressBar = (event) => {
    event.preventDefault();
    const detail = event.detail;
    const dropzoneBar = event.target.firstElementChild;
    const progressBar = dropzoneBar.firstElementChild;
    const perc  = (100.0 / detail.ofTotalBytes) * detail.uploadedBytes;

    // If percentage reached 100% - reset
    if (perc >= 99.8) {
      dropzoneBar.style = {}
      progressBar.style = {}
    } else {
      dropzoneBar.style.padding = 0;
      dropzoneBar.style.background = "none";
      dropzoneBar.style.border = "none";
      dropzoneBar.style.display = "block";
      progressBar.style.height = `${perc}%`;
    }
  };

  acceptDroppedFilesWithDetail({detail}) {
    return this.acceptDroppedFiles(detail);
  }

  disconnect() {
    this.dem.destroy();
  }
}
