import { Controller } from "stimulus"
import { throttle } from "throttle-debounce"
import { getCurrentCSRFToken } from "libraries/libcsrf";

const PASSWORD_CHECK_URL = "/password-strength";

export default class extends Controller {
  connect() {
    this.throttledListener = throttle(30, (evt) => this.updateStrengthHint());
    this.element.addEventListener('input', this.throttledListener);
    this.element.dataset.strengthScore = "blank";
  }

  async updateStrengthHint() {
    const inputValue = this.element.querySelector('input').value;
    const response = await fetch('/password-strength', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCurrentCSRFToken(),
      },
      body: JSON.stringify({password: inputValue, dictionary_items: []})
    });
    const strengthTestResult = await response.json();
    this.element.dataset.strengthScore = strengthTestResult.score;
  }

  disconnect() {
    this.element.removeEventListener('input', this.throttledListener);
  }
}
