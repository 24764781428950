/*
  Creates a "drag slider" behavior for any element that has "data-dragslider" attribute set.
  Will generate the following events on that element:
      
      "dragslider:tap" {detail: 0} will emit when the pointer is put down
      "dragslider:moved" {detail: -12.3} will emit every time pointer moves (no throttling),
          and will contain the horizontal distance from point of tap to current position _in screen pixels._
          The event can be used to animate the related UI controls.
      "dragslider:released" {detail: -12.3} will emit when the pointer is released after being moved,
          and will contain the horizontal distance from point of tap to current position _in screen pixels._
          The event can be used to apply the change to the value in the data model ("control released").
  The events will all bubble.
*/

let initialTapVec = null;
let changeTarget = null;
let totalChange = 0;
let currentPointerId = -1;

const activateForElementsMatching = '[data-dragslider]';

function maybePointerId(evt) {
  if (typeof(evt.pointerId) === 'undefined') return -1;
  return evt.pointerId;
}

function elementOrParentMatching(elem, selector) {
  if (!elem.matches) return null;
  if (!elem.matches(selector)) {
    if (!elem.parentNode) return null;
    return elementOrParentMatching(elem.parentNode, selector);
  } else {
    return elem;
  }
}

function pointerOrMouseEvent(pointerEventName, mouseEventName) {
  if(typeof(PointerEvent) === 'function') {
    return pointerEventName;
  } else {
    return mouseEventName;
  }
}

function suppressEvent(evt) {
  evt.stopPropagation(); // needed for Chrome
  evt.preventDefault(); // needed for Firefox
}

function start() {
  document.addEventListener(pointerOrMouseEvent('pointerdown', 'mousedown'), (evt) => {
    if (changeTarget) return; // If there already is a drag slider interaction happening - go back

    const maybeTarget = elementOrParentMatching(evt.target, activateForElementsMatching);
    if (!maybeTarget) return;

    // Needed so that when we are _inside_ of an element that has an "mousedown" event handler
    // we take precedence. And we need both preventDefault and stopPropagation.
    suppressEvent(evt);

    currentPointerId = maybePointerId(evt);

    initialTapVec = {x: evt.screenX, y: evt.screenY};
    changeTarget = maybeTarget;

    const customEvt = new CustomEvent('dragslider:tap', {bubbles: true, detail: totalChange});
    changeTarget.dispatchEvent(customEvt);
  });

  document.addEventListener(pointerOrMouseEvent('pointermove', 'mousemove'), (evt) => {
    if (!initialTapVec || !changeTarget || !changeTarget.dispatchEvent) return;

    // If the interaction is with a different pointer
    // than the one that started the drag gesture, do not apply the event
    if (maybePointerId(evt) !== currentPointerId) return;

    totalChange = (evt.screenX - initialTapVec.x);
    if (Math.abs(totalChange) < 2) return;

    const customEvt = new CustomEvent('dragslider:moved', {bubbles: true, detail: totalChange});
    changeTarget.dispatchEvent(customEvt);
  });

  document.addEventListener(pointerOrMouseEvent('pointerup', 'mouseup'), (evt) => {
    // If the interaction is with a different pointer
    // than the one that started the drag gesture, do not apply the event
    if (maybePointerId(evt) !== currentPointerId) return;

    // If a drag interaction is in progress on this particular pointer 
    if (initialTapVec && changeTarget && changeTarget.dispatchEvent) {
      // Make sure any click that could have been generated (if our drag slider is inside an anchor element etc.)
      // is suppressed
      document.addEventListener("click", suppressEvent, {once: true});

      const customEvt = new CustomEvent('dragslider:released', {bubbles: true, detail: totalChange});
      changeTarget.dispatchEvent(customEvt);
      initialTapVec = null;
      changeTarget = null;
      totalChange = 0;
    }
  });
}

export default {start};