import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal', 'actuator'];

  hide(evt) {
    if (evt) evt.stopPropagation();
    if (!this.modalTarget.hasAttribute('data-expanded')) return;
    this.modalTarget.removeAttribute('data-expanded');
    this.actuatorTarget.removeAttribute('data-expanded');
    window.removeEventListener("keyup", this.esc.bind(this));
  }

  show(evt) {
    if (evt) evt.stopPropagation();
    if (this.modalTarget.hasAttribute('data-expanded')) return;
    this.modalTarget.setAttribute('data-expanded', true);
    this.actuatorTarget.setAttribute('data-expanded', true);
    window.addEventListener("keyup", this.esc.bind(this));
  }

  esc(evt){
    if (evt.key === "Escape") {
      this.hide();
    }
  }

  disconnect() {
    window.removeEventListener("keyup", this.esc.bind(this));
  }
}
