function pairwiseEach(array, fn) {
  eachCons(array, 2, fn);
}

function pairwiseMap(array, fn) {
  const r = [];
  pairwiseEach(array, (a, b) => r.push(fn(a, b)));
  return r;
}

function eachCons(array, sequenceLength, fn) {
  if (array.length < sequenceLength) return;
  const maxIter = array.length - sequenceLength;
  for (let i = 0; i <= maxIter; i++) {
    fn(...array.slice(i, i + sequenceLength));
  }
}

function eachSeq(array, seqLength, fn) {
  let si = 0;
  while (true) {
    let seq = array.slice(si, si + seqLength);
    si += (seq.length - 1);
    if (seq.length < seqLength) return;
    fn(...seq);
  }
}

function sum(arr) {
  return arr.reduce((accumulator, currentValue) => accumulator + Number(currentValue), 0);
}

function bisect(ptA, ptB) {
  return {x: (ptA.x + ptB.x) / 2, y: (ptA.y + ptB.y) / 2, p: (ptA.p + ptB.p) / 2};
}

function unzip(arrayOfObjects, decomposeFn) {
  const res = [];
  arrayOfObjects.forEach((o) => {
    const values = decomposeFn(o);
    values.forEach((v, i) => {
      if (!res[i]) res[i] = [];
      res[i].push(v);
    });
  });
  return res;
}

function reals(...args) {
  for (let a of args) {
    if (typeof(a) !== 'number' || isNaN(a) || !isFinite(a)) {
      return false;
    }
  }
  return true;
}

function positive(...args) {
  if (!reals(...args)) return false;

  for (let a of args) {
    if (a <= 0) return false;
  }
  return true;
}

export {pairwiseEach, pairwiseMap, eachCons, eachSeq, sum, bisect, unzip, reals, positive};