const VERSION = '3.0.0';
let PRINT_DBG_INFO = true;
const MAX_IN_FLIGHT_REQUESTS = 12;

var debug = function(m){ if (PRINT_DBG_INFO) { return console.debug(m); } };
const log = function(m){ if (PRINT_DBG_INFO) { return console.log(m); } };
const warn = function(m){ if (PRINT_DBG_INFO) { return console.warn(m); } };
const error = function(m){ if (PRINT_DBG_INFO) { return console.error(m); } };

/*
    The bucket for cached frames.

    Will send the following events:
    'frameloaded' (i) - tells a frame has been loaded into the browser
    'frameflushed', (i) - tells a frame has been unloaded
    'frameadded', (url) - tells a frame has been added
    'framecorrupt', (i) - tells a frame could not be loaded
      and sends the dimensions that you can use to configure your Player
*/
const PRELOAD_WAIT = 1000 / 60;

class Eventable {
  constructor() {
    this._eventHandlers = {};
    this._onceHandlers = {};
  }

  trigger(eventName, ...args) {
    if (!Array.isArray(this._eventHandlers[eventName])) {
      this._eventHandlers[eventName] = [];
    }
    if (!Array.isArray(this._onceHandlers[eventName])) {
      this._onceHandlers[eventName] = [];
    }

    const allHandlers = [].concat(this._eventHandlers[eventName], this._onceHandlers[eventName]);
    for (let handlerFn of allHandlers) {
      handlerFn(...args)
    }
    this._onceHandlers[eventName].length = 0;
  }

  on(eventName, handlerFn) {
    if (typeof this._eventHandlers[eventName] === "undefined") {
      this._eventHandlers[eventName] = [];
    }
    this._eventHandlers[eventName].push(handlerFn);
  }

  off(...any) {
    this._eventHandlers = {};
    this._onceHandlers = {};
  }

  once(eventName, handlerFn) {
    if (!Array.isArray(this._onceHandlers[eventName])) {
      this._onceHandlers[eventName] = [];
    }
    this._onceHandlers[eventName].push(handlerFn);
  }
}

class Bucket extends Eventable {
  constructor() {
    super();
    this.continuePreheat = true;
    this.urls = [];
    this.urlFrames = [];
    this.preloadedImages = [];
    this._locked = false;
    this._prioritized = [];
    this._requestsInFlight = 0;
  }

  /*
    Adds a URL to the bucket. You can use data URLs for the images you generate
    yourself, obviously.
  */
  addUrl(imageUrl){
    if (!imageUrl) { throw "No URL given"; }

    this.urls.push(imageUrl);
    this.urlFrames.push(imageUrl.split(".").slice(-2)[0]);
  }

  // Return an Image object for the frame at index i. This is a HOT
  // method since it gets used by the Player that sits on this Bucket.
  fetch(frameAt) {
    if (!this.preloadedImages[frameAt]) {
      this.preload(frameAt);
    }
    return this.preloadedImages[frameAt];
  }

  // Return an image if it's loaded, or null otherwise
  fetchIfLoaded(i){
    const img = this.fetch(i);
    if (img && img.complete) {
      return img;
    } else {
      // Prioritise it for loading instead, and return null.
      // If we are preheating the bucket right now, this frame
      // will be loaded instead of the one that would otherwise
      // be sequenced for loading in order.
      this._prioritized.push(i);
      return null;
    }
  }

  // Preload a frame at index i
  preload(i){
    if (i > this.urls.length) {
      console.error(`Preload index out of bounds - requested ${i} with ${this.urls.length} available`);
      return;
    }

    const img = new Image();
    // http://fragged.org/preloading-images-using-javascript-the-right-way-and-without-frameworks_744.html
    // Make sure the evt fires only once, do not leak the fn
    img.onload = () => {
      // decrease _requestsInFlight for every image being succesfully loaded.
      // See below in preloadInQueueNext for the rationale behind
      // _requestsInFlight
      this._requestsInFlight--;
      this.trigger('frameloaded', i, this.urls[i]);
      return img.onload = null;
    };
    img.onerror = () => {
      // decrease _requestsInFlight when an image errors out so we dont wait for that one anymore
      // See below in preloadInQueueNext for the rationale behind
      // _requestsInFlight
      this._requestsInFlight--;
      this.trigger('framecorrupt', i, this.urls[i]);
      return img.onerror = null;
    };
    // Assign the src attribute after the events. This way if the image already is cached
    // the events will fire immediately
    // See below in preloadInQueueNext for the rationale behind
    // _requestsInFlight
    this._requestsInFlight++;
    img.src = this.urls[i];
    return this.preloadedImages[i] = img;
  }

  /*
    Start the async preloading. Images will be loaded
    one by one, but not sequentially since we can make use of browser
    multiplexing. Every PRELOAD_WAIT milliseconds the next image is going
    to be queued for preload.
  */
  preheat() {
    console.debug(`libplayer Bucket: preloading ${this.urls.length} images`);
    const startAtFrameIndex = 0;
    this.preloadAndQueueNext(startAtFrameIndex);
  }

  /*
    Preloads image at offset i and arms a call to itself after PRELOAD_WAIT
  */
  preloadAndQueueNext(i){
    // If the bucket has been flushed (told to release) stop the preload
    // as to not load extra data asynchronously. This function is called
    // for every frame getting loaded, so we will be able to stop
    // at any frame really.
    if (!this.continuePreheat) return;
    /*
    When we start loading an image, the browser
    adds it to its current pool of HTTP requests.
    When this function gets called, we will try
    to load the requested frame immedately, and
    enqueue loading for the next one with some
    fixed timeout. However, the browser can take
    longer to load images than our timeout is -
    so we are going to set up a ton of those timeouts
    and all of them will start HTTP requests in
    the browser request queue. These requests
    _cannot be canceled_ from JS, meaning that
    even when our bucket is flushed, and `continuePreheat`
    is set to false, the browser _still_ will be busy
    with executing requests on our behalf, for quite some
    time, even when the Bucket and all related objects
    are out of execution scope.

    To avoid that, we maintain a counter of requests which
    are currently in flight. Technically we know that a
    request for the image is going to start once we set the
    `src` property on the `Image` object, and is going to
    finish either with an `onerror` event being triggered
    on that image or with an `onload` event. So our counter
    is incremented once for each image that starts loading,
    and decremented once for each image that finishes loading.
    This way we avoid the requests piling up.

    The place where we _use_ the counter is below - if we
    know that the browser is already knee-deep loading
    our frames we will avoid trying to load anything
    right now, will not schedule loading of the following
    frames and instead will _re-schedule_ ourselves to run
    at a later point in time.
    */
    if (this._requestsInFlight >= MAX_IN_FLIGHT_REQUESTS) {
      setTimeout(this.preloadAndQueueNext.bind(this, i), 150);
      return;
    }
    // Preload the img at this offset
    // and increment as long as the subsequent image happens
    // to have been loaded already
    this.preload(i);
    while (this.preloadedImages[i+1]) {
      i++;
    }
    // And once we found an image that isn't cached yet,
    // preload it on the next timer run
    if (i < (this.urls.length - 1)) {
      setTimeout(this.preloadAndQueueNext.bind(this, i+1), PRELOAD_WAIT);
    }

    // Cheat for the sake of improving the user experience.
    // If there is an image that is prioritised to be loaded - that is,
    // if there is an image that has been recently requested by the player
    // schedule it to load _as well_, with a tighter timing. This allows us
    // to scrub through a "later" part in the clip even though we are
    // still preloading the images from the start of the clip
    if (this._prioritized.length > 0) {
      const prioritizedIndex = this._prioritized.pop();
      setTimeout(this.preload.bind(this, prioritizedIndex), PRELOAD_WAIT / 2);
    }
  }

  /*
    Removes all the cached images
  */
  flush() {
    log(`Flushing ${this.urls.length} frames`);
    // Make sure the preload sequence stops
    this.continuePreheat = false;
    // ...and discard all frames
    Array.from(this.urls).map((n, i) => {
      if (this.preloadedImages[i]) {
        // TODO: Maybe we need to set the image SRC to some image that we _know_
        // is already cached first, so that the browser releases the cached image
        // without waiting for the JS garbage-collection pass
        this.preloadedImages[i] = null;
      }
    });
  }

  /*
    Returns the number of frames contained in the bucket, preloaded or not
  */
  get length() {
    return this.urls.length;
  }
}


class Player extends Eventable {
  constructor(canvas, bucket){
    super();
    this.bucket = bucket;
    this._ctx = canvas.getContext('2d');
    // set default playback rate
    this.currentFrame = 0;

    // Attach basic event handlers
    this.on('goto', i => {
      if (this._locked) { return; }
      return this.counter.goto(i);
    });

    // Once the first frame arrives in the
    // Bucket attached to us, blit it.
    this.bucket.once('frameloaded', i=> {
      log(`First frame arrived (${i}), painting`);
      this.drawFrameAtOffset(i);
    });
  }

  // Draws a frame at a specific offset. Normally you will not call this
  // method externally.
  // WARNING: this is a HOT function.
  drawFrameAtOffset(i) {
    /*
      Bracket the frame being shown to the closest frame available.
      If we are dealing with a rollaround during playback it already has been taken care of.
      However, if we send an event say from the playhead we might get rounding errors and the like - so it's prudent
      to limit the index to a sane value
    */
    const c = this.bucket.length;
    i = Math.min(c, Math.max(0, i));

    if (!this._ctx) {
      throw new Error(`The canvas context for the player was ${this._ctx} and not usable`);
    }

    if (!(i < c)) {
      throw new Error(`Image requested ${i} out of range`);
    }

    try {
      const tex = this.bucket.fetchIfLoaded(i);
      // The bucket returns us an Image or Canvas object, without any guarantees
      // of that image being loaded
      if (!tex) {
        throw new Error("Bucket returned a falsey object, frame dropped");
      }
      if ((tex.nodeName === 'IMG') && !tex.complete) {
        throw new Error("IMG not complete");
      }

      this.trigger('preblit', this._ctx, i);
      this._ctx.drawImage(tex, 0, 0, this._ctx.canvas.width, this._ctx.canvas.height);
      this.trigger('postblit', this._ctx, i);
      this.trigger('atframe', i, c);
      this._ctx.canvas.dispatchEvent(new CustomEvent('atframe', {bubbles: true, detail: i}));
      this.currentFrame = i;
      return true;
    } catch (e) { // DOM Exception is fired if the image is not loaded
      const msg = `Dropping frame ${i} - ${e}, blit will be rescheduled`;
      console.warn(msg);
      setTimeout(this.drawFrameAtOffset.bind(this, i), 250);
      return false;
    }
  }

  // Release all internal references to DOM elements and flush the bucket, release
  // all the event handlers.
  // Call this method when done with the player to avoid leaks.
  destroy() {
    log("Releasing the player object, removing event handlers");
    this._ctx = null;
    this.bucket.flush();
    return this.off(null, null, null);
  }
};

export {Player, Bucket};
