import { Controller } from "stimulus";
import { EventListenerManager } from "libraries/libevents";

export default class extends Controller {

  connect() {
    const numFrames = this.element.dataset.frameCount;
    this.em = new EventListenerManager();
    this.em.add(window, 'comments.commentAdded', this.addCommentDisc.bind(this));
  }

  addCommentDisc(evt) {

  }

  disconnect() {
    this.em.destroy();
  }
}
