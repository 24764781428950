import { Controller } from 'stimulus';
import { EventListenerManager } from "libraries/libevents";

// Modal component controller for the ModalComponent view component
// Usage: Wrap the ModalComponent rails component around the modal template you want to show.
// You can then use hideModal and showModal from libmodal in the controller that activates the modal.
// In your activation controller you can specify a success and cancel method or override them with
// 'data-override-success' and 'data-override-cancel'.
//
// Note! You do need to add 'click->modal-component#cancel' and '..success'
// actions on the buttons you want to use.
//
// When using success or cancel hideModal is used and so you dont need to use hideModal then in your activation controller.
//
// When the modal is shown it moves to the #modal-container div on top of the dom so it covers the ui.
// A original parent is saved in the modal controller instance to safely trnasport it back on closing.
// The parent controller (our activation controller) is also present from this modal controller.
// Targets within the activation controller are not working since the modal is most probably not within
// activation controller anymore. One way to help with this is to save the targets in an initialize function
// on the activation controller.
// Like:
//
// export default class ActivationController extends Controller {
//  initialize() {
//    this.dialog = this.dialogTarget;
//  }
// }
//
// Or you search trough the dom via event.target or the origParent element.
//
// When an element within the modal content has focus: true this element will be focused (like an input for ex)
// when the modal gets shown.
//
export default class extends Controller {
  initialize() {
    this.origParent = this.element.parentElement;
  }

  connect() {
    this.em = new EventListenerManager();
    this.em.add(this.element, 'modal.show', this.show.bind(this));
    this.em.add(this.element, 'modal.hide', this.hide.bind(this));
  }

  focusChild(parentElement) {
    const focusElement = parentElement.querySelector('[focus]');
    if (focusElement) {
      focusElement.focus();
      focusElement.selectionStart = focusElement.value.length;
    }
  }

  hide(evt) {
    if (evt) evt.stopPropagation();
    if (!this.element.hasAttribute('data-expanded')) return;
    this.origParent.appendChild(this.element);
    this.element.removeAttribute('data-expanded');
  }

  show(evt) {
    if (evt) evt.stopPropagation();
    if (this.element.hasAttribute('data-expanded')) return;
    // I do the the parent controller here in the show instead of initialize to be a bit more efficient when we have a lot of modals.
    // Otherwise it should be in initialize and before the element gets moved.
    this._setParentController();
    document.body.firstElementChild.appendChild(this.element);
    this.element.setAttribute('data-expanded', true);
    this.focusChild(this.element);
  }

  success(evt) {
    if (evt.target.dataset.overrideSuccess) {
      this.parentController[evt.target.dataset.overrideSuccess](evt);
    } else {
      typeof this.parentController.success === 'function' && this.parentController.success(evt);
    }
    this.hide();
  }

  cancel(evt) {
    if (evt.target.dataset.overrideCancel) {
      this.parentController[evt.target.dataset.overrideCancel](evt);
    } else {
      typeof this.parentController.cancel === 'function' && this.parentController.cancel(evt);
    }
    this.hide();
  }

  disconnect() {
    this.em.destroy();
  }

  _setParentController() {
    // Save the parent controller and its element so that we have a link to the controller from which the modal is called and can call success and cancel function on.
    this.parentControllerElement = this.origParent.closest('[data-controller]');
    this.parentController = this.application.getControllerForElementAndIdentifier(this.parentControllerElement, this.parentControllerElement.dataset.controller);
  }
}
