class BoundingRect {
  constructor() {
    this.left = 0;
    this.top = 0;
    this.bottom = 0;
    this.right = 0;
  }

  extend(x, y, strokeRadius) {
    this.left = Math.min(this.left, x - strokeRadius - 1);
    this.right = Math.max(this.right, x + strokeRadius + 1);
    this.top = Math.min(this.top, y - strokeRadius - 1);
    this.bottom = Math.max(this.bottom, y + strokeRadius + 1);
    return Object.assign({}, this);
  }

  toObject() {
    return Object.assign({}, this);
  }
}

function unionRects(...rects) {
  return rects.reduce((rect, {left, right, top, bottom}) => {
    left = Math.min(rect.left, left);
    top = Math.min(rect.top, top);
    right = Math.max(rect.right, right);
    bottom = Math.max(rect.bottom, bottom);
    return {left, right, top, bottom}
  }, {left: 0, right: 0, top: 0, bottom: 0});
}

export { BoundingRect, unionRects};