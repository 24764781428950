/*

libconnect is like a teeny-tiny Redux. Imagine you have something happening on the
page in widget A, and that should affect the state of widgets B and C. In the React
world it gets solved by using something like Redux, where the widget A dispatches an "action"
(a message) into the Redux store. Redux in turn processes the action (message) into a state change
using reducers, and then notifies any React components that have subscribed to a specific bit of state
that the state has changed.

We are going to do something similar here, but waaay smaller.

The basic primitive are two functions - write(key, value) for setting
a value, and read(key) for reading that value, and a single object called "subscription".
The subscription is your "handle" to be notified of the changes of a specific "key". As many
consumers can subscribe to changes as one would want.

So imagine you have a setting for "trobnicate-rabilators". In your Stimulus controller
you would do:

connect() {
  this.settingSubscription =  subscribe("trobnicate-rabilators", this.updateTrobnicate.bind(this));
  this.updateTrobnicate(); // Set the default/initial value
}

updateTrobnicate() {
  const value = this.settingSubscription.read(true); // true is the default value if none is set
  // .....
}

disconnect() {
  this.settingSubscription.cancel(); // unsibscribe from updates
}

*/

const _subscribersPerKey = {}
const _state = {}
const NO_VALUE = Symbol();

/*
There work is done with cycles to prevent circular updates.
In the "tick" phase, we process any calls to write() - 
replace the values and record that notifications have to be dispatched
to the subscribers.
In the "tick" phase (which will always be on the next frame of the
animation loop) we dispatch notifications to subscribers.

This does introduce a 1-frame lag but guarantees that doing a write()
will not call anything in the same frame, and thus avoid creating
an infinite stack (infinite loop) by triggering something else
that calls write()
*/
let _phase = TICK_PHASE;
let _nextWrites = [];
let _immediateWrites = [];
const _libconnectStorage = window.localStorage;
const TICK_PHASE = Symbol();
const TOCK_PHASE = Symbol();
const LIBCONNECT_LOCAL_STORAGE_KEY = "libconnectState"

function syncLoop() {
  try {
    if (_phase === TICK_PHASE) {
      // Move any pending writes to the immediate writes
      // and empty them out
      _immediateWrites = _nextWrites.slice();
      _nextWrites.length = 0;
    } else {
      // We do not empty _immediateWrites because the next tick phase
      // will reset them anyway, even if one of the callbacks throws.
      for (let [keyName, newValue] of _immediateWrites) {
        if (newValue === NO_VALUE) {
          delete _state[keyName];
        } else {
          _state[keyName] = newValue;
        }
        notifyAllSubscribers(keyName, newValue);
      }
      // If we did any writes save the modified state to localStorage
      if (_immediateWrites.length > 0) {
        _libconnectStorage.setItem(LIBCONNECT_LOCAL_STORAGE_KEY, JSON.stringify(_state));
      }
    }
  } finally {
    // Switch phase
    _phase = _phase === TICK_PHASE ? TOCK_PHASE : TICK_PHASE;
    requestAnimationFrame(syncLoop);
  }
}
syncLoop(); // start the loop

class Subscription {
  constructor({keyName, cb}) {
    this.cb = cb;
    this.keyName = keyName
    if (!_subscribersPerKey[keyName]) {
      _subscribersPerKey[keyName] = new Set();
    }
    _subscribersPerKey[keyName].add(this);
  }

  // Same as read(keyName) but will automatically
  // use the keyName stored in the Subscription
  read(...args) {
    return read(this.keyName, ...args);
  }

  // Same as write(keyName, newValue) but will automatically
  // use the keyName stored in the Subscription
  write(newValue) {
    return write(this.keyName, newValue);
  }

  cancel() {
    _subscribersPerKey[this.keyName].delete(this);
    this.cb = null; // Help the garbage collector a bit
  }
}

function notifyAllSubscribers(keyName, newValue) {
  if (_subscribersPerKey[keyName]) {
    for (let sub of _subscribersPerKey[keyName]) {
      if (typeof(sub.cb) === "function") {
        try {
          sub.cb(newValue);
        } catch (e) {
          console.groupCollapsed(`libconnect: ${e} thrown when notifying subscriber on ${keyName}`);
          console.error(e);
          console.groupEnd();
        }
      }
    }
  }
}

// Subscribes to updates of a certain key. The "cb" is the callback function
// that will be called on the next animation frame after the value of the key changes.
// It returns a Subscription object which can be canceled by calling `cancel()` on it.
// The subscription object also allows write() and read()
function subscribe(keyName, cb) {
  return new Subscription({keyName, cb});
}

// Writes the value to the store. There is no way to delete a key at the moment.
function write(keyName, newValue) {
  _nextWrites.push([keyName, newValue]);
  return true;
}

// Returns the value of the given key stored in state, or NO_VALUE if none.
function read(keyName, fallbackValue = NO_VALUE) {
  if (Object.keys(_state).includes(keyName)) {
    return _state[keyName];
  } else {
    return fallbackValue;
  }
}

// Deletes the value altogether. Subscribers will receive NO_VALUE as the
// new value of the key after the deletion has taken place
function remove(keyName) {
  _nextWrites.push([keyName, NO_VALUE]);
  return true;
}

// When loading, hydrate _state from localStorage
let recoveredJsonState = _libconnectStorage.getItem(LIBCONNECT_LOCAL_STORAGE_KEY);
if (recoveredJsonState) {
  Object.assign(_state, JSON.parse(recoveredJsonState));
}

export { subscribe, write, read, remove, NO_VALUE };
