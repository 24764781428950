import { Controller } from "stimulus"
import { EventListenerManager } from "libraries/libevents";
import Stam from "libraries/stam"
import { fetchProjectURLPrefix } from "libraries/libdropfile";
import { pushSubscribe } from "libraries/libpush"

function currentProjectSlug() {
  try {
    return document.querySelector("meta[name=project-slug]").getAttribute("content");
  } catch (e) { // When not in project context
    return "_none_";
  }
}

export default class extends Controller {
  initialize() {
    this.sm = new Stam("idle", "reflowing").assume("idle");
    this.sm.permitTransition("idle", "reflowing", "idle");
    this.sm.permitTransition("reflowing", "reflowing");
  }

  connect() {
    this.reflowEventManager = new EventListenerManager();
    this.reflowEventManager.add(this.element, "reflow:selector", this.handleReflow.bind(this));

    const chan = `/reflows/for-project/${currentProjectSlug()}`;
    this.subscription = pushSubscribe(chan, this.messageHandler.bind(this));
  }

  messageHandler({selector}) {
    const reflowEvent = new CustomEvent("reflow:selector", {bubbles: true, detail: selector});
    this.element.dispatchEvent(reflowEvent);
  }

  async handleReflow(event) {
    const cssSelector = event.detail;

    console.info(`Reflow for ${cssSelector} was requested`);

    // TODO: If we are in process of reflowing now, enqueue the reflow for later
    this.sm.transitionTo("reflowing");

    try {
      const elementsToRefresh = Array.from(this.element.querySelectorAll(cssSelector));
      if (this.element.matches(cssSelector)) {
        elementsToRefresh.push(this.element);
      }

      const reflowableElements = elementsToRefresh.filter((el) => el.dataset.reflowUrl);
      console.debug(`Asked to reflow matching ${cssSelector}, will reflow ${reflowableElements.length}"`);

      // Useful to know: a map over an async function returns an array of Promises
      const reflowPromises = reflowableElements.map(async (el) => {
        const partialGetURL = el.dataset.reflowUrl;
        const fetchResponse = await fetch(partialGetURL)

        // If the object referred to is 404 or 410 remove the current representation from the DOM
        if (fetchResponse.status == 404) {
          el.parentNode.removeChild(el); // Remove the old node
          return;
        }

        // If the response is not OK, raise and abort
        if (!fetchResponse.ok) {
          throw new Error(`Reloading ${partialGetURL} failed: server responded with ${fetchResponse.status}`);
        }

        // Convert the response HTML string into a DOM node we can use for replacement
        const partialHtml = await fetchResponse.text();
        const partialFragment = document.createRange().createContextualFragment(partialHtml);
        const replacementNode = partialFragment.firstChild;
        el.insertAdjacentHTML('afterend', partialHtml); // Insert the replacement
        el.parentNode.removeChild(el); // Remove the old node
      })
      await Promise.all(reflowPromises);
    } finally {
      this.sm.transitionTo("idle");
    }
  }

  disconnect() {
    this.subscription.cancel();
    this.reflowEventManager.destroy();
  }
}
