import { Controller } from "stimulus";

// Fit the elements using explicit sizing on the inner
// element. The function is external to the controller
// since we don't need access to the controller instance
// variables to apply this function
function fitElementToParent(innerElement, outerElement, allowOvrezoom) {
  // The width and the height are of the fitting container (what we fit inside of)
  // Now we need to determine the aspect ratio of the first canvas in the element
  const firstCanvas = innerElement.querySelector('canvas')
  const {width, height} = outerElement.getBoundingClientRect();

  let desiredCanvasHeight = width;
  let desiredCanvasWidth = height;

  const boundingAspect = width / height;
  const canvasAspect = firstCanvas.width / firstCanvas.height;

  const desiredHeight = width / canvasAspect;
  const desiredWidth = height * canvasAspect;

  // If the image is very small and can be displayed 1:1 size
  // we can actually display it pixel-for-pixel
  const aboveNaturalSize = width > firstCanvas.width && height > firstCanvas.height;

  if (aboveNaturalSize && !allowOvrezoom) {
    // Reset the style of the viewport to auto
    innerElement.style.height = 'auto';
    innerElement.style.width = 'auto';
  } else {
    // This is the switch native CSS does not provide us:
    // we need to change the fit mode depending on the aspects of
    // both the container and the contained.
    if (canvasAspect > boundingAspect) {
      // Image has a wider aspect than the bounding box, so we fit width
      innerElement.style.height = `${desiredHeight.toFixed(2)}px`;
      innerElement.style.width = '100%';
    } else {
      // Image has a taller aspect than the bounding box, so we fit height
      innerElement.style.width = `${desiredWidth.toFixed(2)}px`;
      innerElement.style.height = '100%';
    }
  }
}

export default class extends Controller {
  // We need two targets.
  // The outerBox defines in which area
  // we need to do our fitting.
  // The inner box is the one that we fit.
  //
  // The CSS dimensions of the innerBox
  // get updated by this controller so that
  // it always fits neatly into the outerBox.
  static targets = [ "outerBox", "innerBox"];

  connect() {
    const adjustFn = (resizedEntries) => {
      resizedEntries.forEach((_entry) => {
        // The entry contains the "target" which is what we
        // want to monitor, but our controller has the outer
        // area which we are monitoring. So we don't need the
        // entry.
        const allowOverzoom = document.fullscreenElement ? true : false;
        fitElementToParent(this.innerBoxTarget, this.outerBoxTarget, allowOverzoom);
      });
    }
    this.resizeObserver = new ResizeObserver(adjustFn);
    this.resizeObserver.observe(this.outerBoxTarget);
  }

  disconnect() {
    this.resizeObserver.disconnect();
    this.resizeObserver = null;
  }
}
