import { Controller } from 'stimulus';
import { hideModal, showModal } from 'libraries/libmodal';
import { SimpleLinkMenu, getButtonSurface } from "libraries/libmenu";

export default class extends Controller {
  static targets = [ "menuContainer", "optionNode", "activateBtn", "titleInput", "modal",
                     "versionTitle"];

  connect() {
    this.menus = {};
    this.activateBtnTargets.forEach((btn, index) => {
      let id = btn.dataset.id;
      this.menus[btn.dataset.id] = new SimpleLinkMenu(
        { menuContainer: this.menuContainerTargets.find(el => el.dataset.id === id),
          optionNodes: Array.from(this.optionNodeTargets.filter(el => el.dataset.id === id)) }
      )
    })
  }

  renameVersion(evt) {
    evt.preventDefault();
    const form = evt.target.closest("form");
    const formData = new FormData(form);

    fetch(form.action, {
      method: 'post',
      body: formData
    }).then(response => {
      if (response.status === 200) {
        return response.json();
      }
    }).then(json => {
      this.versionTitleTarget.innerText = json['title'];
    })
  }

  showModal(evt) {
    evt.preventDefault();
    showModal(this.modalTarget);
  }

  activateMenu(evt) {
    const target = getButtonSurface(evt)
    this.activatedId = target.dataset.id;
    this.menus[this.activatedId].activateMenu(evt);
  }

  disconnect() {
    Object.values(this.menus).forEach(menu => menu.cleanup());
  }
}
