/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// First all the builtin Rails goodness
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from 'activestorage';

// For regenerator support (async/await)
import "@babel/polyfill"

Rails.start();
Turbolinks.start();
ActiveStorage.start();

//...then polyfills
import dialogPolyfill from 'dialog-polyfill';

//...then Stimulus
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

// The folowing methods are copied from stimulus/weback-helpers since we want to make a distinction
// between the component controllers and the regular stimulus controllers (which should be unchanged)
// Component controllers are simplified, '-component' is automatically added if not there
// and the folder name is removed to keep it shorter.
//
// !!! From now on the naming convention for stimulus controllers should be: !!!
// - name_controller.js for regular controllers.
// - components/name_component/name_component_controller.js or components/name/name_controller.js for component controllers.


function componentDefinitionsFromContext(context) {
  return context.keys()
    .map(key => componentDefinitionForModuleWithContextAndKey(context, key))
    .filter(value => value);
};

function componentDefinitionForModuleWithContextAndKey(context, key) {
  const identifier = componentIdentifierForContextKey(key)
  if (identifier) {
    return definitionForModuleAndIdentifier(context(key), identifier)
  }
};

function definitionForModuleAndIdentifier(module, identifier) {
  const controllerConstructor = module.default;
  if (typeof controllerConstructor == "function") {
    return { identifier, controllerConstructor }
  }
};

function componentIdentifierForContextKey(key) {
  // Chops off the controller from the name and keeps only name and folder
  //
  // key: "./example_component/example_component_controller.js"
  // logicalName: "example_component/example_component"
  const logicalName = (key.match(/^(?:\.\/)?(.+)(?:[_-]controller\..+?)$/) || [])[1]
  if (logicalName) {
    // logicalName: "example_component/example_component"
    // returns: "example-component"
    // or
    // logicalName: "example/example"
    // returns: "example-component"
    return logicalName.split("/").slice(-1)[0].replace(/_/g, "-").replace(/-component/g, "") + "-component"
  }
};

const application = Application.start();
// regular controllers
const context = require.context("controllers", true, /.js$/);
// view-component controllers
const contextComponents = require.context("../../components", true, /_controller.js$/)

const standaloneControllerDefinitions = definitionsFromContext(context);
const componentControllerDefinitions = componentDefinitionsFromContext(contextComponents);
const allControllerDefinitions = [].concat(standaloneControllerDefinitions, componentControllerDefinitions);

application.load(allControllerDefinitions);

document.addEventListener('turbolinks:before-cache', function() {
  application.controllers.forEach(function(controller){
    if(typeof controller.teardown === 'function') {
      controller.teardown();
    }
  });
});

// then some extra behaviors
import ds from "libraries/libdragslider";
ds.start();

import { Brush } from "libraries/libbrush";
import "../components";
