import { Controller } from "stimulus";
import { Player, Bucket } from "libraries/libplayer";
import { playerKeydownHandler } from "libraries/libplayerkeys";
import { EventListenerManager } from "libraries/libevents";
import Stam from "libraries/stam";
import { AudioTimeSource, AudioVideoSync } from "libraries/libausync";
import { throttle, debounce } from "throttle-debounce";
import { eventUV, mux, withLazyUpdate, clamp } from "libraries/libmisc";
import { formatTimecodeFromSeconds } from "libraries/libtimecode";
import { CacheBar } from "libraries/libcachemap";

const FALLBACK_FRAMERATE = 24.0;
const BG_LIGHTER2 = "#3D4D5C";

export default class extends Controller {
  static targets = [ "screen", "framerefs", "playhead", "cachebar",
                     "scrubbar", "frameNum", "wrapper",
                     "timer", "playBtn", "loopBtn", "fullscreenWrapper"]

  fullscreenChangeHandler(evt) {
    if (document.fullscreenElement === null) { // Exiting fullscreen
      this.fullscreenWrapperTarget.classList.remove('in-fullscreen-state');
      const commentForm = document.querySelector(".commentForm");
      commentForm && commentForm.classList.remove("is-invisible");
    } else { // Entering fullscreen
      this.fullscreenWrapperTarget.classList.add('in-fullscreen-state');
      const commentForm = document.querySelector(".commentForm");
      commentForm && commentForm.classList.add("is-invisible");
    }
  }

  // Activated from the fullscreen button or fullscreen key.
  // The related state changes are handled by the even thandler in fullscreenChangeHandler
  toggleFullscreen(evt) {
    // if we are not in fullscreen
    if (!document.fullscreenElement) {
      this.fullscreenWrapperTarget.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  play() {
    this.clockSource.play();
  }

  pause() {
    this.clockSource.pause();
  }

  frameForward() {
    const frameDuration = 1 / this.framerate;
    const maxTime = this.duration - frameDuration;
    this.clockSource.currentTime = clamp(0, this.clockSource.currentTime + frameDuration, maxTime);
  }

  frameBack() {
    const frameDuration = 1 / this.framerate;
    const maxTime = this.duration - frameDuration;
    this.clockSource.currentTime = clamp(0, this.clockSource.currentTime - frameDuration, maxTime);
  }

  jumpToFrame(frameIndex) {
    const frameDuration = 1 / this.framerate;
    const maxTime = this.duration - frameDuration;
    this.clockSource.currentTime = clamp(0, frameDuration * frameIndex, maxTime);
  }

  gotoIn() {
    this.clockSource.currentTime = 0;
  }

  gotoOut() {
    // Move to the start of last frame (not the end of our duration)
    const oneFrameDuration = 1 / this.framerate;
    const maxTime = this.duration - oneFrameDuration;
    this.clockSource.currentTime = maxTime
  }

  frameLinkNoScrollClickHandler(evt) {
    const frame = evt.target.closest("div[data-frame]").getAttribute("data-frame");
    this.clockSource.pause();
    this.jumpToFrame(frame);
  }

  frameLinkClickHandler(evt) {
    this.frameLinkNoScrollClickHandler(evt);
    window.scrollTo(0, 0);
  }

  jumpToFrameFromDataAttr() {
    const targetFrameIdxStr = this.wrapperTarget.dataset.frame;
    const targetFrameIdx = parseInt(targetFrameIdxStr)
    this.framesBucket.preload(targetFrameIdx);
    this.jumpToFrame(targetFrameIdx);
  }

  connect() {
    const frameUrls = JSON.parse(this.framerefsTarget.innerText);
    this.framesBucket = new Bucket();
    for (let url of frameUrls) {
      this.framesBucket.addUrl(url);
    }
    // Init player and attach canvas
    this.player = new Player(this.screenTarget, this.framesBucket);

    // Set up the frame cache indication bar
    const cachebar = new CacheBar(this.cachebarTarget, this.framesBucket.length, BG_LIGHTER2);
    this.framesBucket.on('frameloaded', (i) => cachebar.didLoad(i));

    // Schedule image loading
    this.framesBucket.preheat(); // ...and start loading frames

    // Resize the playhead to match the number of frames
    const playheadWitdhPercent = clamp(2, 100 / frameUrls.length, 100);
    this.playheadTravelLimit = 100 - playheadWitdhPercent;
    this.playheadTarget.style.width = `${playheadWitdhPercent}%`;

    this.framerate = parseFloat(this.wrapperTarget.dataset.framerate || FALLBACK_FRAMERATE);
    this.duration = frameUrls.length / this.framerate;

    this.clockSource = new AudioTimeSource();
    this.clockSource.addEventListener("libausync.audioloaded", () => this.playBtnTarget.removeAttribute("disabled"), {once: true});

    if (this.wrapperTarget.dataset.audioTrackSrc) {
      this.clockSource.loadSound(this.wrapperTarget.dataset.audioTrackSrc);
    }

    this.jumpToFrameFromDataAttr();

    // Updates the playhead position
    const updatePlayheadPosition = (frameIndex) => {
      const percentLeft = frameIndex / (this.framesBucket.length - 1) * this.playheadTravelLimit;
      this.playheadTarget.style.left = `${percentLeft}%`;
    };

    // Updates the frame display
    const updateFrameDisplay = (frameIndex, seconds) => {
      const tc = formatTimecodeFromSeconds(seconds, this.framerate);
      this.timerTarget.innerText = `${tc} (${frameIndex})`;
    };

    // Caps the clock progression to the end frame, and loops it around
    // if player loop mode is enabled
    const loopOrNudgeToLastFrame = (frameIndex, seconds, isPaused) => {
      if (frameIndex < (this.framesBucket.length - 1)) return;

      if (this.loopBtnTarget.classList.contains("on") && !isPaused) {
        // Loop the clock around
        this.clockSource.currentTime = 0.0;
      } else {
        // Nudge the clock a little so that it stops at _the start_ of
        // the last frame' duration.
        this.clockSource.pause();
        this.clockSource.currentTime = this.duration - (1 / this.framerate);
      }
    }

    const updatePlayPauseButton = (frameIndex, time, isPaused) => {
      if (isPaused) {
        delete this.playBtnTarget.dataset.playing;
      } else {
        this.playBtnTarget.dataset.playing = "true";
      }
    };

    const updateFrameNumberAttrs = (idx) => {
      // The data attribute that gets rendered by the server
      this.wrapperTarget.dataset.frame = idx;
      // If present - the value for the hidden form field
      // that sets the frame number a comment is associated to
      if (this.hasFrameNumTarget) {
        this.frameNumTarget.value = idx;
      }
    };

    // Wrap the render function with a lazy update - if the time value
    // does not change it will not redraw UI. When it does get called,
    // dispatch multiple functions at once, and _only_ change UI state through it.
    //
    // This function receives 3 arguments - the frame index,
    // the seconds of the clock and the audio.paused flag.
    const updateViews = withLazyUpdate(
      loopOrNudgeToLastFrame,
      updatePlayheadPosition,
      updateFrameDisplay,
      updatePlayPauseButton,
      // Drawing the frame can use its own lazy update since granularity
      // is lower - at frame level, not at time level
      withLazyUpdate((idx) => this.player.drawFrameAtOffset(idx)),
      // No need to poke at this data attribute too often either
      throttle(16, withLazyUpdate(updateFrameNumberAttrs)),
    );

    const frameAndTime = (drawAtSeconds) => {
      const targetFrameIndex = Math.floor(drawAtSeconds * this.framerate);
      const clampedFrameIndex = clamp(0, targetFrameIndex, (this.framesBucket.length - 1));
      // Quantize the time value to our frame offsets
      const secondsFrameBased = clampedFrameIndex / this.framerate;
      return [clampedFrameIndex, secondsFrameBased];
    }

    // ...and finally wrap _all of these_ into the function that
    // the audio sync loop will call for us.
    // Inside that function we will also make sure we update views to a time that
    // is consistent with the start of a frame, as AudioContext time just keeps
    // on ticking even during a frame/redraw, and might be different.
    const syncViewsToTime = (timeSeconds, isPaused) => {
      const [frameIndex, frameTime] = frameAndTime(timeSeconds);
      updateViews(frameIndex, frameTime, isPaused);
    };

    // For normal playback - especially with audio - install a requestAnimationFrame loop
    // which calls our updates by itself on every refresh
    this.auSync = new AudioVideoSync(this.clockSource, syncViewsToTime);

    // Init shortcuts
    this.eventManager = new EventListenerManager();
    this.eventManager.add(window, 'keydown', playerKeydownHandler);
    this.eventManager.add(window, 'playerkeys.playpause', this.playOrPause.bind(this));
    this.eventManager.add(window, 'playerkeys.play', this.playOrPause.bind(this));
    this.eventManager.add(window, 'playerkeys.pause', this.pause.bind(this));
    this.eventManager.add(window, 'playerkeys.jogforward', this.play.bind(this));
    this.eventManager.add(window, 'playerkeys.frameforward', this.frameForward.bind(this));
    this.eventManager.add(window, 'playerkeys.framebackward', this.frameBack.bind(this));
    this.eventManager.add(window, 'playerkeys.fullscreen', this.toggleFullscreen.bind(this));
    this.eventManager.add(window, 'playerkeys.toggleloop', this.toggleLoop.bind(this));
    this.eventManager.add(document, 'fullscreenchange', this.fullscreenChangeHandler.bind(this));
  }

  playOrPause(evt) {
    // If we are on the last frame - start playing from the beginning
    const frameDuration = 1 / this.framerate;
    const lastFrameTime = this.duration - frameDuration;

    if (this.clockSource.paused && (this.clockSource.currentTime >= lastFrameTime)) {
      this.clockSource.currentTime = 0.0;
    }

    if (this.clockSource.paused) {
      this.clockSource.play();
    } else {
      this.clockSource.pause();
    }
  }

  toggleLoop() {
    if (this.loopBtnTarget.classList.contains("on")) {
      this.loopBtnTarget.classList.remove("on");
    } else {
      this.loopBtnTarget.classList.add("on");
    }
  }

  scrubStarted(evt) {
    evt.preventDefault();
    const moveHandler = (moveEvt) => {
      this.clockSource.isScrubbing = true;
      const euv = eventUV(moveEvt, this.scrubbarTarget);
      const u = clamp(0, euv.u, 1);
      const duration = this.framesBucket.length / this.framerate;
      const secondsOffset = duration * u;
      this.clockSource.currentTime = secondsOffset;
    }

    // If it is a momentary "tap" also scrub, right now
    moveHandler(evt);
    // Add as a passive event as we are not going to be preventing anything
    document.addEventListener('mousemove', moveHandler, {passive: true});
    document.addEventListener('mouseup', (evt) => {
      this.clockSource.isScrubbing = false;
      document.removeEventListener('mousemove', moveHandler);
    }, {passive: true, once: true});
  }

  disconnect() {
    this.eventManager.destroy();
    this.auSync.destroy();
    this.clockSource.pause();
    this.clockSource.destroy();
    this.player.destroy();
  }
}
