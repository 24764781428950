import { Controller } from "stimulus"
import { write, read, NO_VALUE } from "libraries/libconnect"

// Simple toggle switch controller which saves and load from localstorage
// via libconnect.
// Currently only found value is checked true, which will work fine with for ex a radio button
//
export default class extends Controller {
  static targets = ["option"];

  connect() {
    const preference = read(this.data.get('name'));

    if (preference !== NO_VALUE) {
      this.optionTargets.find( option => option.id === preference).checked = true;
    }
  }

  inputChanged(e) {
    write(this.data.get('name'), e.target.id);
  }
}
