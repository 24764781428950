import { Controller } from "stimulus";
import { getCurrentCSRFToken } from "libraries/libcsrf";

const NOTIFICATION_ANIM_SPEED = 500;
const NOTIFICATION_REMOVE_TIME = 5000;

function freshParamsForFetch() {
  return {
    method: 'GET',
    headers: {
      "Content-Type": ["text/html"],
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": getCurrentCSRFToken(),
    }
  }
}

export default class NotificationsController extends Controller {

  static targets = ['notificationList', 'notification', 'historyModal', 'countPill', 'historyBackpanel'];

  fetchNotification(identifier) {
    return fetch(`/notifications/${identifier}`, freshParamsForFetch()).then(res => {
      if (res.ok) return res.text();
    });
  }

  fetchNotificationsHistory() {
    return fetch(`/notifications/notifications_history`, freshParamsForFetch()).then(res => res.text());
  }

  setNewNotificationHighlight() {
    if (!this.hasCountPillTarget) return;
    this.countPillTarget.setAttribute("data-messages", true);
  }

  removeNewNotificationHighlight() {
    if (!this.hasCountPillTarget) return;
    this.countPillTarget.removeAttribute("data-messages");
  }

  createElementFromHTML(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();
    return div.firstChild;
  }

  removeNotificationAfter(notification, millis) {
    setTimeout(() => {
      notification.classList.add("notification__invisible");
      setTimeout(() => notification.remove(), NOTIFICATION_ANIM_SPEED);
    }, millis);
  }

  showNotificationsHistory() {
    this.fetchNotificationsHistory().then((response) => {
      if (response) {
        this.removeNewNotificationHighlight();
        this.historyModalTarget.innerHTML = "";
        const historyList = this.createElementFromHTML(response);
        this.element.setAttribute('notification-messages-active', true);
        this.historyModalTarget.appendChild(historyList);
      }
    })
  }

  showNotification(identifier) {
    console.debug(`Received signed notif ID ${identifier}`);
    this.fetchNotification(identifier).then((response) => {
      this.setNewNotificationHighlight();

      if (this.hasNotificationListTarget) {
        const newNotification = this.createElementFromHTML(response);
        this.notificationListTarget.insertBefore(newNotification, this.notificationListTarget.childNodes[0]);

        setTimeout(() => {
          newNotification.classList.remove("notification__invisible");
          this.removeNotificationAfter(newNotification, NOTIFICATION_REMOVE_TIME);
        }, 50);
      }
    })
  }

  notificationChannelConnected(data) {
    console.log('NotificationChannel connected :', data);
  }

  notificationChannelDisconnected(data) {
    console.log('NotificationChannel disconnected: ', data);
  }

  initialize() {
    let subscription = App.cable.subscriptions.subscriptions.find(s => s.identifier.includes("NotificationChannel"));

    // The subscription gets created in notification_channel.js since we only want it once.
    // Here we add the received function since it relies on this stimulus controller.
    if (subscription && subscription.received === null) {
      subscription.received = this.showNotification.bind(this);
    }

  }
}
