import { computeCentroid, distanceBetweenCursorAndPoint } from "./libmisc";
import { throttle } from 'throttle-debounce';
import { EventListenerManager } from "./libevents";

const CANCEL_DIST = 350;
// Class that gives control over a dragselect menu that selects buttons based on closestdistance
// Advantage is that button selection can occur outside of the button container
//
// menuContainer: Container with selectable items that can be hidden or shown
// optionNodes: List of selectable items
// selectAction: function that is run when item is selected
// CANCEL_DIST is the max distance where within selection occurs. Outside it falls back to previous value
// the value that will be passed to selectAction is a data-value attribute value of the optionNode.
//
export function getButtonSurface(evt) {
  return evt.target.tagName === "SPAN" ? evt.target : evt.target.closest("SPAN");
}

export class BrushSelectorMenu {
  constructor({ menuContainer, optionNodes, selectAction, cancelDist } = {}) {
    this.menuActive = false;
    this.optionNodes = optionNodes;
    this.menuContainer = menuContainer;
    this.selectAction = selectAction;
    this.cancelDist = cancelDist || CANCEL_DIST,
    this.oldSelectedValue = null;
    this.selectedValue = null;
    this.mainSections = Array.from(document.body.children).filter(el => el.tagName === "SECTION")
  }


  activateMenu(evt) {
    if (this.menuActive) return;
    this.menuActive = true;
    this.oldSelectedValue = this.selectedValue || 1;

    this.menuContainer.classList.remove("is-hidden");
    // We turn pointer-events and user select off for the "main" div instead of document.body.
    // The brush cursor changes happen in document.body which is above main.
    // Since pointer-events: none disbles all cursor changes below as well
    // it wont affect our brush cursor updates.
    this.mainSections.forEach(el => {
      el.style.pointerEvents = "none";
      el.style.userSelect = "none";
    });
    document.addEventListener("mousemove", throttle(10, (evt) => { this.menuChangeSelection(evt) }) );
    document.addEventListener("mouseup", () => { this.deactivateMenu(); }, {once: true});
    document.addEventListener("keydown", this.checkEscape.bind(this) );
    this.menuChangeSelection(evt);
  }

  deactivateMenu() {
    if (!this.menuActive) return;
    this.menuActive = false;
    this.menuContainer.classList.add("is-hidden");
    document.removeEventListener("mousemove", () => { this.menuChangeSelection(); });
    document.removeEventListener("keydown", this.checkEscape.bind(this) );
    // reset body and main again.
    document.body.style = {};
    this.mainSections.forEach(el => el.style = {});
    this.optionNodes.forEach(el => el.classList.remove("selected"));
  }

  checkEscape(e) {
    if (e.keyCode === 27 ) { // escape key
      this.selectAction && this.selectAction(this.oldSelectedValue);
      this.deactivateMenu();
    }
  }

  menuChangeSelection(evt) {
    if (!this.menuActive) return;
    const containerDist = distanceBetweenCursorAndPoint(evt, computeCentroid(this.menuContainer));
    if (containerDist > this.cancelDist) {
      this.optionNodes.forEach(el => el.classList.remove("selected"));
      this.selectAction && this.selectAction(this.oldSelectedValue);
      return;
    }
    const elementsWithDistancesFromCursor = this.optionNodes.map((el, i) => {
      return {el, i, distance: distanceBetweenCursorAndPoint(evt, computeCentroid(el))};
    });
    // Find the closest option to the cursor
    elementsWithDistancesFromCursor.sort((a, b) => a.distance - b.distance);
    elementsWithDistancesFromCursor.map(({el}) => el.classList.remove('selected'));

    const closestElementWithDistanceAndIndex = elementsWithDistancesFromCursor[0];
    this.selectedValue = closestElementWithDistanceAndIndex.el.dataset.value;
    closestElementWithDistanceAndIndex.el.classList.add('selected');
    this.selectAction && this.selectAction(this.selectedValue);
  }
}

export class SimpleLinkMenu {
  constructor({menuContainer, optionNodes} = {}) {
    this.menuContainer = menuContainer;
    this.optionNodes = optionNodes;
    this.em = new EventListenerManager;
    this.optionNodes.forEach((el) => {
      this.em.add(el, "mouseup", () => el.click())
    });
  }

  cleanup() {
    this.em.destroy();
  }

  deactivateMenu() {
    document.removeEventListener("keydown", this.checkEscape.bind(this) );
    this.optionNodes.forEach(el => el.style = {});
    document.body.style = {};
    this.menuContainer.classList.add("is-hidden");

    document.body.style.pointerEvents = this._preservedPointerEventsStyle;
    document.body.style.userSelect = this._preservedUserSelectStyle;
  }

  activateMenu(evt) {
    this._preservedPointerEventsStyle = document.body.style.pointerEvents;
    this._preservedUserSelectStyle = document.body.style.userSelect;
    document.body.style.pointerEvents = "none";
    document.body.style.userSelect = "none";
    this.optionNodes.forEach(el => el.style.pointerEvents = "auto");
    document.addEventListener("mouseup", () => { this.deactivateMenu(); }, {once: true});
    document.addEventListener("keydown", this.checkEscape.bind(this) );
    this.menuContainer.classList.remove("is-hidden");

    // The event used for activating the menu will likely be
    // a mousedown event. In our setup we have mousedown handling on the entire
    // shot card which, after a little timeout, will start the drag action on the card.
    // To stop this from happening we need to "swallow" the event here so that the drag-reorder
    // does not receive the event.
    evt.stopPropagation();
  }

  checkEscape(e) {
    if (e.keyCode === 27 ) { // escape key
      this.deactivateMenu();
    }
  }
}
