import { Controller } from "stimulus";
import { activeTasks, taskListGeneration, createClientBytesTask, updateServerTask } from "libraries/libprogression";
import { render as mustacheRender } from "mustache";

const TASK_LIST_UPDATE_INTERVAL = 500;

require('../libraries/message-bus.js');

export default class extends Controller {
  static targets = ['template', 'task', 'tasks'];

  connect() {
    MessageBus.callbackInterval = 900;
    MessageBus.start();

    // The last seen message ID (the MessageBus message ID, which is always monotonically increasing)
    // is stored in local storage.
    // In browser tests this value in local storage is saved, and therefore MessageBus will not receive
    // the notification as it thinks it already saw it (!)
    // To cheat MessageBus out of that behavior we will do a dirty and fetch "last relevant and the N previous"
    const retrieveN = -1;
    MessageBus.subscribe("/progression", updateServerTask, retrieveN);

    this.gen = 0;
    this.intervalId = window.setInterval(this.updateTasks.bind(this), TASK_LIST_UPDATE_INTERVAL);
  }

  // Will always remove the current task list and repopulate it. With this kind of workload the React model
  // of "re-render the world" actually works much better than the Stimulus models of bitwise updating.
  // We will be able to do this more efficiently if we have some "update counter" on libprogression which
  // will function similarly to shouldComponentUpdate in React - allowing us to skip a re-render.
  updateTasks() {
    const [tasks, generation] = [activeTasks(), taskListGeneration()];

    // Avoid unnecessary DOM updates
    if (this.gen === generation) return;

    if (tasks.length > 0) {
      this.element.setAttribute('progression-active-tasks', true);
    } else {
      this.element.removeAttribute('progression-active-tasks');
    }

    // Regardless of the outcome we clear the task list on every re-render (for now)
    this.taskTargets.forEach((node) => node.remove());
    tasks.forEach((task) => this.renderTask(task));

    // Mark the last rendered generation
    this.gen = generation;
  }

  renderTask(task) {
    const templateCode = `
      <div class="task" data-target="progression.task">
        <div class="description">{{description}}</div>
        <div class="meter">
          <progress value="{{percentComplete}}" max="100">{{percentComplete}}%</progress>
        </div>
      </div>
    `;

    const taskHtml = mustacheRender(templateCode, task);
    this.tasksTarget.insertAdjacentHTML('beforeend', taskHtml);
  }

  disconnect() {
    MessageBus.unsubscribe("/progression", updateServerTask);
    window.clearInterval(this.intervalId);
  }
}
