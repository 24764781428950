import filesize from 'file-size';

// https://github.com/ai/nanoid/blob/master/index.js
function nanoid(size) {
  var url = '_~varfunctio0125634789bdegjhklmpqswxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  size = size || 21;
  var id = '';
  while (0 < size--) {
    id += url[Math.random() * 64 | 0];
  }
  return id;
}

// The _gen is the "cache generation" variable. When the tasks we know
// about change, the variable gets incremented and the consumers of this
// code then know that the task views need to be refreshed.
let _gen = 0;

// We store the tasks identified by the ID. For server-side tasks, the tasks will be
// assigned an ID by the server. For client side tasks we generate one using nanoid
let _knownTasks = {};

const HIDE_UPON_COMPLETION_AFTER_MILLIS = 5000;

// A purely client-side task primarily used for uploading data
class BytesTask {
  constructor({description, bytesTotal}) {
    this.failed = false;
    this._startedMillis = performance.now();
    this.completed = false;
    this._desc = description;
    this.bytesRemaining = bytesTotal;
    this.bytesDelivered = 0;
    this.id = nanoid();
    _knownTasks[this.id] = this;
    _gen++;
  }

  get description() {
    const done = filesize(this.bytesDelivered, {fixed: 0}).human();
    const total = filesize(this.bytesRemaining + this.bytesDelivered, {fixed: 0}).human();
    if (this.failed) {
      return `${this._desc} - Failed at ${done}`
    } else if (this.completed) {
      return `${this._desc} - ${done} complete.`
    } else {
      return `${this._desc} - ${done} of ${total}`;
    }
  }

  get percentComplete() {
    const floatPercent = (this.bytesDelivered / (this.bytesRemaining + this.bytesDelivered)) * 100;
    return Math.max(0, Math.min(100, Math.ceil(floatPercent)));
  }

  get terminated() {
    return (this.completed || this.failed || false);
  }

  setDelivered(bytes) {
    const total = this.bytesRemaining + this.bytesDelivered;
    this.bytesDelivered = bytes;
    this.bytesRemaining = total - bytes;
    _gen++;
    return this.bytesDelivered;
  }

  incrementBy(bytes) {
    this.bytesRemaining -= Math.round(bytes);
    this.bytesDelivered += Math.round(bytes);
    _gen++;
    return this.bytesDelivered;
  }

  _removeFromActiveTasks() {
    _gen++;
    delete _knownTasks[this.id];
  }

  markFailed() {
    _gen++;
    this.failed = true;
    this.completed = false;
    setTimeout(this._removeFromActiveTasks.bind(this), HIDE_UPON_COMPLETION_AFTER_MILLIS);
    return true;
  }

  markCompleted() {
    _gen++;
    this.failed = false;
    this.completed = true;
    setTimeout(this._removeFromActiveTasks.bind(this), HIDE_UPON_COMPLETION_AFTER_MILLIS);
    return true;
  }
}

// A "view" into a task taking place on the server side
class ServerTask {
  constructor({id, ...rest}) {
    this.id = id;
    _knownTasks[this.id] = this;
    this.updateWithDataFromServer(rest);
    _gen++;
  }

  get terminated() {
    return (this.completed || this.failed || false);
  }

  updateWithDataFromServer({description, percentComplete, completed, failed, serverSecondsSpent}) {
    _gen++;
    this._serverSecondsSpent = serverSecondsSpent;
    this.percentComplete = percentComplete;
    this.description = description;
    if (failed) {
      this.markFailed();
    } else if (completed) {
      this.markCompleted();
    }
  }

  _removeFromActiveTasks() {
    _gen++;
    delete _knownTasks[this.id];
  }

  markFailed() {
    this.completed = false;
    this.failed = true;
    
    _gen++;
    setTimeout(this._removeFromActiveTasks.bind(this), HIDE_UPON_COMPLETION_AFTER_MILLIS);

    const failureEvent = new CustomEvent('progression:taskfailed', {bubbles: true, detail: this});
    document.dispatchEvent(failureEvent);
    return true;
  }

  markCompleted() {
    this.percentComplete = 100;
    this.completed = true;
    this.failed = false;

    _gen++;
    setTimeout(this._removeFromActiveTasks.bind(this), HIDE_UPON_COMPLETION_AFTER_MILLIS);

    const completionEvent = new CustomEvent('progression:taskdone', {bubbles: true, detail: this});
    document.dispatchEvent(completionEvent);
    return true;
  }
}

function activeTasks() {
  return Object.values(_knownTasks);
}

// Returns the current "cache generation" value for the state of all known Tasks. If this value changes
// it means the list of tasks can be re-rendered.
function taskListGeneration() {
  return _gen;
}

// Returns a new BytesTask which is only tracked on the client - in this browser window to be precise.
// The task has to be manually terminated using `markCompleted()` or `markFailed()`.
function createClientBytesTask(options) {
  return new BytesTask(options);
}

// When a notification is received that a server-side task (like a background job) wants to
// relay its status, this function can be called with the payload the server sends. The payload
// is already going to contain an `id` value for the task, and this value stays stable throughout the
// updates. If a Task with this ID is already known to Progression, it will be updated. If it isn't,
// the task is going to be added to Progression and initialized with the server-provided data.
function updateServerTask(options) {
  if (_knownTasks[options.id]) {
    _knownTasks[options.id].updateWithDataFromServer(options);
  } else {
    new ServerTask(options);
  }
}

export { updateServerTask, createClientBytesTask, activeTasks, taskListGeneration };
