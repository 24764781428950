/*
/// Recommented DOM setup:

<div class="gallery" id="g2">
  <div id="x1" class="gslot reorderableItem" >1</div>
  <div id="x2" class="gslot reorderableItem" >2</div>
  <div id="x3" class="gslot reorderableItem" >3</div>
  <div id="x4" class="gslot reorderableItem" >4</div>
  <div id="x5" class="gslot reorderableItem" >5</div>
</div>


/// Recommended CSS for "in-betweener" highlights (in this case for horizontal flow of sortables)

.reorderableItem:before,
.reorderableItem:before {
  content: " ";
  position: absolute;
  top: 0px;
  left: -6px;
  right: -6px;
  bottom: 0px;
  border: 3px solid transparent;
}

.reorderableItem.will-accept-after:before {
  border-right: 3px solid blue;
}
.reorderableItem.will-accept-before:before {
  border-left: 3px solid blue;
}

*/

import { throttle } from 'throttle-debounce';
import { EventListenerManager } from "libraries/libevents";

function matchingChildrenOf(containerElement, matchingSelector) {
  return Array.from(containerElement.querySelectorAll(matchingSelector));
}

function moveInArray(arr, fromIndex, toIndex) {
  arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
}

const BEFORE = Symbol();
const AFTER = Symbol();
const LIST_FLOW_HORIZONTAL = Symbol("horizontal")
const LIST_FLOW_VERTICAL = Symbol("vertical")
const AUTO = Symbol("auto")
const HOLD_DRAG_ON_MOUSEDOWN_MILLIS = 30;

// Tells whether the event is likely to be willing to place the element
// being dragged before, or after the element given in `element`
function insertBeforeOrAfter(evt, element, direction) {
  const ctr = computeCentroid(element);
  if (direction === LIST_FLOW_HORIZONTAL && evt.clientX < ctr.x) {
    return BEFORE;
  } else if (direction === LIST_FLOW_VERTICAL && evt.clientY < ctr.y) {
    return BEFORE;
  }
  return AFTER;
}

// Compute the centroid of an element in _page_ coordinates
// (from the top-left of the page, accounting for the scroll).
// We need to account for the scroll here because it is not only possible,
// but actually _used_ by many that with long lists you can scroll while
// you drag - pick an item, focus over the destination drop area and then scroll
// using the wheel to "reposition" the area for your drop. Check this out, really -
// it works like this in native macOS controls since ages.
//
// Also, one of the very good indications of web-engine based apps posing as native:
// scroll-during-drag not working correctly. We will not be like those apps.
function computeCentroid(element) {
  const rect = element.getBoundingClientRect();
  const viewportX = (rect.left + rect.right) / 2;
  const viewportY = (rect.top + rect.bottom) / 2;
  return {x: viewportX + window.scrollX, y:  viewportY + window.scrollY};
}

function distanceBetweenCursorAndPoint(evt, centroid) {
  return Math.hypot(centroid.x - evt.clientX - window.scrollX, centroid.y - evt.clientY - window.scrollY);
}

function detectListFlowDirection(firstElement, secondElement) {
  if (!firstElement || !secondElement) {
    return LIST_FLOW_HORIZONTAL;
  }

  const ca = computeCentroid(firstElement);
  const cb = computeCentroid(secondElement);
  const dx = Math.abs(ca.x - cb.x);
  const dy = Math.abs(ca.y - cb.y);
  if (dy > dx) {
    return LIST_FLOW_VERTICAL;
  }

  return LIST_FLOW_HORIZONTAL;
}

/*
Reorders values in a given orderedSet for "move multiple selected items to position"
reorder interactions. This one took a day to get right so it is officially
XO, or at least VSOP vintage.

It works for the following general case:
* You select any elements from the ordered set
* These elements get dragged to some target index in orderedSet. Target index is 0-based.
  0 is "to the left of element currently at 0".
* When dropped, the elements get moved to the desired position. The order of the moved
  group of elements matches their order in the valuesToMove argument.

To place the elements at the end of the new set, set the toIndex to orderedSet.length.

It is a bit coutnerintuitive but it works like this:

withGroupRepositioned(["a", "b", "c", "d"], ["a", "b"], 0) // [ 'a', 'b', 'c', 'd' ]
withGroupRepositioned(["a", "b", "c", "d"], ["a", "b"], 1) // [ 'a', 'b', 'c', 'd' ]
withGroupRepositioned(["a", "b", "c", "d"], ["a", "b"], 2) // [ 'a', 'b', 'c', 'd' ]
withGroupRepositioned(["a", "b", "c", "d"], ["a", "b"], 3) // [ 'c', 'a', 'b', 'd' ]
withGroupRepositioned(["a", "b", "c", "d"], ["b", "a"], 2) // [ 'b', 'a', 'c', 'd' ]
withGroupRepositioned(["a", "b", "c", "d"], ["b", "a"], 3) // [ 'c', 'b', 'a', 'd' ]
withGroupRepositioned(["a", "b", "c", "d"], ["a", "c"], 4) // [ 'b', 'd', 'a', 'c' ]

and delivers the correct interaction. Trust me.
*/
function withGroupRepositioned(inOrderedSet, valuesToReposition, toIndex) {
  // Do not damage the given set
  const cpy = [].concat(inOrderedSet);

  // Step 1. Replace all traveling elements with placeholders, in place.
  const placeholder = Symbol();
  valuesToReposition.forEach((v) => cpy[cpy.indexOf(v)] = placeholder);

  // Step 2. Reinsert values as a group, where we want them.
  cpy.splice(toIndex, 0, ...valuesToReposition);

  // Step 3. Remove placeholders.
  return cpy.filter((v) => v !== placeholder);
}



let LAST_RESTYLED_SIBLING_ELEMENT = null;

function createReorderableBehavior(containerElement, {draggableItemSelector}) {
  if (!draggableItemSelector) {
    throw new Error("draggableItemSelector is a required option for createReorderableBehavior()");
  }
  const handlerPool = new EventListenerManager();
  const handlersDuringDrag = new EventListenerManager();
  let startDragTimeoutId = null;

  handlerPool.add(containerElement, 'mousedown', (evt) => {
    const maybeClosest = evt.target.closest(draggableItemSelector);
    if (!maybeClosest) return; // If the event is outside of a draggable item

    // Use a timeout before we make the element draggable, since this mousedown
    // might be succeeded by a mouseup right after - which means a single click instead.
    startDragTimeoutId = setTimeout(() => {
      maybeClosest.draggable = true;
    }, HOLD_DRAG_ON_MOUSEDOWN_MILLIS);
  });

  handlerPool.add(containerElement, 'mouseup', (evt) => {
    const maybeClosest = evt.target.closest(draggableItemSelector);
    if (!maybeClosest) return; // If the event is outside of a draggable item

    // If we are waiting to start a drag, stop waiting and cancel the drag so that we do
    // not get phantom drags for what are actually clicks.
    if (startDragTimeoutId) {
      clearTimeout(startDragTimeoutId);
      startDragTimeoutId = null;
    }
  });

  handlerPool.add(containerElement, 'dragstart', (evt) => {
    if (!evt.target.matches(draggableItemSelector)) return; // Allow the event to bubble

    const elementBeingDragged = evt.target.closest(draggableItemSelector);
    evt.stopPropagation();

    console.debug("libreorderable: dragstart");

    containerElement.classList.add("accepting-reorder-drag");
    const draggableElements = matchingChildrenOf(containerElement, draggableItemSelector);
    const sourceIndex = draggableElements.indexOf(elementBeingDragged);
    console.debug(`libreorderable: element ${sourceIndex} of eligible children is traveling`);

    // Notify up that a reorder started
    const startingIndices = draggableElements.map((_, i) => i);
    const startedReorderEvt = new CustomEvent("libreorderable:started", {detail: {startingIndices, sourceIndex}, bubbles: true});
    containerElement.dispatchEvent(startedReorderEvt);

    // Firefox will not start drag&drop without data on the event and we need it on drop as well
    evt.dataTransfer.setData("libreorder/traveling-element-index", sourceIndex.toString());
    evt.dataTransfer.effectAllowed = "move";

    // Where we are going to be storing the indices of elements once reordered
    let targetIndex = sourceIndex;

    // We need a flag variable to prevent "stale" dragover events that arrive after "drop" or "dragend"
    let active = true;

    const unstyle = () => {
      if(LAST_RESTYLED_SIBLING_ELEMENT) {
        LAST_RESTYLED_SIBLING_ELEMENT.classList.remove("will-accept-after", "will-accept-before");
      }
      elementBeingDragged.classList.remove("will-accept-after", "will-accept-before");
    };

    handlersDuringDrag.add(containerElement, 'drop', (evt) => {
      console.debug("libreorderable: drop");

      // Make sure all dragover events henceforth are neutralized, as there may be some arriving
      // even after drop/dragend get called
      active = false;

      // ESSENTIAL: for the case the dropped thing is a link, we need to prevent default
      // otherwise the browser interprets it as "URL dragged into browser window" and navigates to the link
      evt.preventDefault();

      // Later on we can supply multiple elements to move in [sourceIndex] when we have multiselect ;-)
      const indicesBefore = draggableElements.map((_, i) => i);
      const indicesAfter = withGroupRepositioned(indicesBefore, [sourceIndex], targetIndex);
      if (JSON.stringify(indicesBefore) === JSON.stringify(indicesAfter)) {
        console.debug(`libreorderable: order remained the same`);
        const changedEvt = new CustomEvent("libreorderable:unchanged", {detail: indicesAfter, bubbles: true});
        containerElement.dispatchEvent(changedEvt);
      } else {
        console.debug(`libreorderable: order changed to ${indicesAfter}, dispatching libreorderable:changed`);
        const changedEvt = new CustomEvent("libreorderable:changed", {detail: indicesAfter, bubbles: true});
        containerElement.dispatchEvent(changedEvt);
      }
    }, {once: true});

    handlersDuringDrag.add(containerElement, 'dragover', (evt) => evt.preventDefault()); // otherwise "drop" event won't fire

    // Detect whether we are dealing with a horiznotal or vertical list flow
    const listFlow = detectListFlowDirection(draggableElements[0], draggableElements[1]);
    if (listFlow === LIST_FLOW_HORIZONTAL) {
      console.debug(`libreorderable: detected horizontal list flow`);
    } else {
      console.debug(`libreorderable: detected vertical list flow`);
    }

    // Precompute the centroids of the elements participating in the reorder upfront, to minimize DOM
    // querying during dragging.
    const centroids = draggableElements.map((element) => computeCentroid(element));

    // https://bugzilla.mozilla.org/show_bug.cgi?id=505521
    // in Firefox "drag" event does not carry any usable coordinates, so we use "dragover" instead.
    // The event needs to be throttled to prevent too many calculations (we compute distances to
    // the centroids of all of our elements).
    const dragoverHandler = (evt) => {
      // Dragover events can, amazingly, keep arriving even after we received "drop" or "dragend".
      if (!active) return;

      // Compute distances between the cursor and every element in the ordered sequence. Now,
      // if there were a guarantee this was an only horizontal list, or only vertical list,
      // we could compute those distances based on a binary search within the list of elements.
      // We could then "track forward" to find the element with the shortest distance using
      // a small number of lookups - and computing those distances a much smaller number of times.
      // However, we are likely to be dealing with sequences of elements that span multiple
      // rows while laid out using a horizontall flow. Therefore you do not have a uniform
      // distribution of distances over the elements. Imagine a layout like this:
      //
      // [A][B][C]
      // [D][E][F]
      //
      // where you are trying to place the element being dragged between [C] and [D]. The distance
      // from [A] is going to be small, the distance from [D] is going to be the smallest, but the
      // distance to [C] is going to be the largest. Therefore we can only divide and conquer if
      // we know the composition of the rows (compute the closest center of the row, then
      // within the closest row find the closest element). This would change if the elements are
      // positioned vertically. So in practice it would complicate the algorithm substantially, and
      // the algorithm's performance would be very intimately dependent on the number number of rows/columns.
      // Given the above, and the fact that JS math is reasonably fast, we are going to use a brute-force approach.
      // There is one performance issue there though which is that to determine the centroid of the bounding box
      // of a particular element we have to query the DOM. We will assume that the window shape will not be
      // changing during drag, and therefore that the document is not going to be reflowing - we are abusing
      // the fact that we do not display a "placement preview" with our new element already positioned in
      // the new spot. Since that is the case and during drag the elements are not going to be moving around
      // on the page, we can cache their dimensions once at the start of the drag operation and use the centroid
      // reuse the centroid coordinates during drag, only computing the trigonometry. Win!
      const distances = draggableElements.map((el, i) => {
        return {el, i, distance: distanceBetweenCursorAndPoint(evt, centroids[i])};
      })

      // If there are no distances - we are reordering a list of 1 element, so we can simply return early
      if (distances.length === 0) return;

      // Rank siblings in the ascending order of distance from cursor. The closest sibling to where
      // we are dragging is our reference - we assume the user's intent is to insert the dragged
      // element either right before or right after that sibling. We use proximity and not the location
      // of the item "between" two siblings because of the following reasons:
      // 1. This would make us compute quite some trigs to isolate the space delimited by two lines
      //    between each couple of adjacent siblings
      // 2. This would create odd situations where two siblings in the dom are located on the opposite
      //    sides of the screen
      // 3. This would create odd situations where we drag outside of the space between any siblings
      // Distances just work better for this.
      distances.sort((a, b) => a.distance - b.distance);
      // Take the closest sibling to cursor and figure out whether the intention is to put
      // it before or after it. This is the one we are going to sort relative to
      const closestSibling = distances.shift();
      const directionIntent = insertBeforeOrAfter(evt, closestSibling.el, listFlow);

      // Unstyle the previous sibling we touched
      // and style only the element "relative to which" we are placing things
      unstyle();
      LAST_RESTYLED_SIBLING_ELEMENT = closestSibling.el;
      closestSibling.el.classList.add(directionIntent === BEFORE ? "will-accept-before" : "will-accept-after");

      // memorize the position the element wants to travel to
      targetIndex = closestSibling.i + (directionIntent === BEFORE ? 0 : 1);
    };
    handlersDuringDrag.add(document, 'dragover', throttle(30, dragoverHandler), {passive: true});

    // Setup handlers which are _only_ actuve until dragend fires
    handlersDuringDrag.add(document, 'dragend', (evt) => {
      console.debug("libreorderable: dragend");

      // Make sure all dragover events henceforth are neutralized
      active = false;
      containerElement.classList.remove("accepting-reorder-drag");
      unstyle();

      evt.target.draggable = false;
      handlersDuringDrag.destroy();
    }, {once: true});
  });

  return handlerPool;
}

export {createReorderableBehavior}
