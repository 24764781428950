import { Controller } from "stimulus";
import { write, read, NO_VALUE } from "libraries/libconnect"

// Simple controller to manage switching tabs
//
// Add the tabs controller on top
// Buttons need an id and the tabChanged action
// (for radio buttons a parentclass which has this)
// contents that need to be switched need the 'tabContent' target and the
// data-buttonid attribute with the id of the button.
//
// So far this becomes a bit more specific with the state save
// so will have to move this into a component

export default class TabsController extends Controller {
  static targets = ["tabContent", "tabRadio"];

  connect() {
    const selectedTabId = read('selectedLeftSidebarTab');

    if (selectedTabId !== NO_VALUE) {
      this.tabRadioTargets.find( tab => tab.id === selectedTabId).checked = true;
      this.showSelectedTabContent(selectedTabId);
    }
  }

  tabChanged(e) {
    this.showSelectedTabContent(e.target.id);
    write('selectedLeftSidebarTab', e.target.id);
  }

  showSelectedTabContent(id) {
    this.tabContentTargets.forEach(content => {
      if (content.dataset.buttonid === id) {
        content.classList.remove("is-hidden");
      } else {
        content.classList.add("is-hidden");
      }
    });
  }
}
